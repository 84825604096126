import { ENC_KEY_AES256GCM } from "../config/Constants";
const forge = require('node-forge');

// Test Session String Compress. 
// import zlib from 'zlib';

// import Gzip from 'rn-gzip';

// let {Gunzip, unzip, DEFLATE, INFLATE, DEFLATERAW, UNZIP} = require('react-zlib-js');
// import stream  from 'stream-browserify'
// const stream = require('node:stream');

// const Zlib = require('browserify-zlib');
// const { deflate, unzip } = require('node:zlib');

// import Zlib  from 'node-gzip';

// const { pipeline } = require('node-stream');


// export const AESGCM_DECRYPT = function(data) {
//   // log.debug(data)
//     const bytes = forge.util.hexToBytes(data);
//     const iv = bytes.slice(0, 12);
//     const tag = bytes.slice(12, 28);
//     const ciphertext = bytes.slice(28);
//     const binKey = forge.util.hexToBytes(ENC_KEY_AES256GCM);
//     const decipher = forge.cipher.createDecipher('AES-GCM',binKey);
//     decipher.start({ iv, tag });
//     decipher.update(forge.util.createBuffer(ciphertext));
//     decipher.finish();
//     const bufferbase64 =  (decipher);
  
  // //   GUNZIP(res);
  
  // log.debug(bufferbase64);
  // // log.debug(res);
  // // const buffer =res;
  
  // const buffer = bufferbase64;//forge.util.createBuffer(bufferbase64);
  
  
  // UNZIP(buffer, (err, buffer) => {
  //   if (err) {
  //     console.error('An error occurred:', err);
  //     process.exitCode = 1;
  //   }
  //   log.debug(buffer.toString());
  // });
  
  
  
  // log.debug('<#------------------------------------------');
  //   var res = 'chitranshbhala';
  //   var compressData = res.split('').map(function(e) {
  //     return e.charCodeAt(0);
  // });
  
  // var inflate = new zlib.Inflate(compressData);
  // var output = inflate.decompress();
  
  
  // var base64Data = "eJztwTEBAAAAwqD1T20JT6AAAHgaCWAAAQ==";
  // var compressData = atob(base64Data);
  // var compressData = compressData.split('').map(function(e) {
  //     return e.charCodeAt(0);
  // });
  
  // var inflate = new Zlib.Inflate(compressData);
  // var output = inflate.decompress();
  // log.debug(compressData);
  // log.debug('------------------------------------------#>');
  
  
  
  // }
  
  // export const AESGCM_DECRYPT_OLD = function(data) {
  
  //   const bytes = forge.util.hexToBytes(data);
  //   const iv = bytes.slice(0, 12);
  //   const tag = bytes.slice(12, 28);
  //   const ciphertext = bytes.slice(28);
  //   const binKey = forge.util.hexToBytes(ENC_KEY_AES256GCM);
  //   const decipher = forge.cipher.createDecipher('AES-GCM',binKey);
  //   decipher.start({ iv, tag });
  //   decipher.update(forge.util.createBuffer(ciphertext));
  //   decipher.finish();
  //   return   forge.util.binToUtf8(decipher.output.data);
  
  
  //   const decompressed =  decompress(res);
  // log.debug(decompressed);
  // log.debug(decompressed.toString());     
  
  //   let unzipData = decompressed.toString();
  
  //   zlib.unzip(new buffer(data), function (error, result) {
  //     if (error) throw error;
  //       log.debug("Result:- ",result.toString());
        
   
  //  })
  
  //   zlib.unzip(forge.util.createBuffer(res), function (error, result) {
  //     if (error) throw error;
  //       log.debug("Result:- ",result.toString());
  //  });
    // zlib.gzip(input, function (error, result) {
    //   if (error) throw error;
    //     log.debug(result.toString());
    //     data=result;
   
      
    // }) 
    // pipeline(res, zlib.createGunzip(), output, onError);
  
    // const unzipData = zlib.INFLATE((res));
    // log.debug(unzipData);
  
    // log.debug(binToUtf8(unzipData));
    // log.debug(unzipData.toString());
    // return unzipData
  
  
  // }

export const AESGCM_DECRYPT = function(data) {
  const bytes = forge.util.hexToBytes(data);
  const iv = bytes.slice(0, 12);
  const tag = bytes.slice(12, 28);
  const ciphertext = bytes.slice(28);
  const binKey = forge.util.hexToBytes(ENC_KEY_AES256GCM);
  const decipher = forge.cipher.createDecipher('AES-GCM',binKey);
  decipher.start({ iv, tag });
  decipher.update(forge.util.createBuffer(ciphertext));
  decipher.finish();
  return binToUtf8(decipher.output.data);
}
export const AESGCM_ENCRYPT = function(data) {
  const iv = forge.random.getBytesSync(12);
  const cipher = forge.cipher.createCipher('AES-GCM',forge.util.hexToBytes(ENC_KEY_AES256GCM));
  cipher.start({ iv, tagLength: 16 });
  cipher.update(forge.util.createBuffer(utf8ToBin(data)));
  cipher.finish();
  return forge.util.bytesToHex(iv + cipher.mode.tag.data + cipher.output.data);
}
function utf8ToBin(data) {
  if (typeof Buffer !== 'undefined') {
    // node
    return Buffer.from(data, 'utf8').toString('binary');
  } else if (typeof TextEncoder !== 'undefined') {
    // modern browsers
    const encoder = new TextEncoder();
    const buf = encoder.encode(data);
    let bin = '';
    buf.forEach(function(i) {
      bin += String.fromCharCode(i);
    });
    return bin;
  } else {
    // slower but vanilla js
    const escstr = encodeURIComponent(data);
    const bin = escstr.replace(/%([0-9A-F]{2})/gi, function($0, hex) {
      return String.fromCharCode(parseInt(hex, 16));
    });
    return bin;
  }
}
function binToUtf8(data) {
  if (typeof Buffer !== 'undefined') {
    // node
    return Buffer.from(data, 'binary').toString('utf8');
  } else if (typeof TextDecoder !== 'undefined' && typeof Uint8Array !== 'undefined') {
    // modern browsers
    const decoder = new TextDecoder('utf-8');
    const arr = [];
    data.split('').forEach(function(c) {
      arr.push(c.charCodeAt(0));
    });
    return decoder.decode(Uint8Array.from(arr));
  } else {
    // slower but vanilla js
    const escstr = data.replace(/./g, function(char) {
      let code = char
        .charCodeAt(0)
        .toString(16)
        .toUpperCase();
      if (code.length < 2) {
        code = '0' + code;
      }
      return '%' + code;
    });
    return decodeURIComponent(escstr);
  }
}