import log from "loglevel";
import axios from 'axios'
import { Session } from 'bc-react-session';
import { AESGCM_DECRYPT } from "../utils/aes-256-GCM";
import {  IS_LOCALHOST } from '../config/Constants';
import { SESSION_API_URL,LOCAL_LOGIN_PATH,SYSTEM_ERROR } from "./ServiceConstants";
import i18n from "i18next";

export const getSessionData = (postData) => {
    return new Promise(async(resolve, reject) => {
        try {
            
            await  axios
                .post(IS_LOCALHOST?LOCAL_LOGIN_PATH:SESSION_API_URL+'?fetchSession=1',postData)
                .then((res) => {
                    // return false;
                    if ( !res ) {

                        reject("Invalid Login !"); 
                    }

                    if( res.data.error ){
                        reject(res.data.error);
                    }
                    else if ( !res.data.error ) {
        
                        const {payload} = Session.get('login');
                        
                        let decryptedString = AESGCM_DECRYPT(res.data.data);
                        let decryptedArr = JSON.parse(decryptedString);

                        if (payload.status  === "loggedin") {
                            i18n.changeLanguage((decryptedArr.DashboardLanguage).toLowerCase())
                            localStorage.setItem('login_language',(decryptedArr.DashboardLanguage).toLowerCase());
                            // Session.setPayload(decryptedArr)
                            return false;
                        } else {

                            log.debug('Session not there',payload);
                        }

                        if (decryptedArr.status === 'loggedin') {
                            i18n.changeLanguage((decryptedArr.DashboardLanguage).toLowerCase())
                            Session.start('login',{expiration: decryptedArr.expirationReact?decryptedArr.expirationReact:2000});
                            Session.setPayload(decryptedArr)
                            log.debug('Login Success Session data : ',decryptedArr)
                            resolve(true)

                        } else {

                            reject("Invalid Login !"); 
                        }

                    } else {

                        reject("Invalid Login !"); 
                    }
                })
                .catch((err) => {

                    reject("Error in session axios!",err);
                });

        } catch (error) {
            
            reject(SYSTEM_ERROR);
        }
    });
};

export const checkSessionData = (postData) => {
    return new Promise(async(resolve, reject) => {
        try {
            await  axios
                .post(IS_LOCALHOST?LOCAL_LOGIN_PATH:SESSION_API_URL+'?fetchSession=1',postData)
                .then((res) => {
                    // return false;
                    if (!res) {

                        reject("Invalid Login !"); 
                    }

                    if ( !res.data.error ) {
            
                        let decryptedString = AESGCM_DECRYPT(res.data.data);
                        let decryptedArr = JSON.parse(decryptedString);

                        if (decryptedArr.status === 'loggedin') {

                            // Session.start('login');
                            // Session.setPayload(decryptedArr)
                            resolve(true)

                        } else {

                            reject("Invalid Login !"); 
                        }

                    } else {

                        reject("Invalid Login !"); 
                    }
                })
                .catch((err) => {

                    reject("Error in session axios!",err);
                });

        } catch (error) {

            reject(SYSTEM_ERROR);
        }
    });
};

export const demoLoginSession = (postData) => {

    log.debug('demoLoginSession axios');
    
    return new Promise(async(resolve, reject) => {
        try {
            axios.post(SESSION_API_URL,postData)
            .then((res) => {

                if ( !res ) {

                    reject("Invalid Login !"); 
                }

                if ( !res.data.error ) {

                    let decryptedString = AESGCM_DECRYPT(res.data.data);
                    const {payload} = Session.get('login');
                    let decryptedArr = JSON.parse(decryptedString,true);

                    if (typeof decryptedArr.mainSession === "undefined") {

                        decryptedArr.mainSession= payload;
                    }

                    if (decryptedArr.status === 'loggedin') {
                        
                        // Session.start('login');
                        // Session.start('login',{expiration: decryptedArr.expirationReact?decryptedArr.expirationReact:2000});
                        
                        Session.setPayload(decryptedArr)
                        log.debug('theuserID login',decryptedArr);
                        resolve(true)

                    } else {

                        reject("Invalid Login !"); 
                    }

                } else {
                    
                    reject("Invalid Login !"); 
                }

            })
            .catch((err) => {

                reject("Error",err);
            });
            
        } catch (error) {

            reject(SYSTEM_ERROR);
        }
    });

}

export const updatePHPSession = (postData) => {

    return new Promise(async(resolve, reject) => {

        try {

            axios.post(SESSION_API_URL,postData)
            .then((res) => {

                resolve(res);
            })
            .catch((err) => {

                reject("Error",err);
            });

        } catch (error) {

            reject(SYSTEM_ERROR);
        }
        
    });

}

// Logout PHP when react logout is triggered  (Only required for multiple enviornments)
// export const logoutPHP = (postData) => {
//     return new Promise(async(resolve, reject) => {
//         try {
//             // log.debug('axios Session Started >>>>>>')
//             // console.timeLog("answer time");
//             axios.get(PHP_LOGOUT_URL)
//             .then(response => '')
//                 .catch((err) => {
//                     reject("Error in php logout (src/services/sessionServices.js)");
//                 });
//         } catch (error) {
//             reject(SYSTEM_ERROR);
//         }
//     });
// };