import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { BASE_URL, IS_LOCALHOST } from "./config/Constants";
import packageJson from '../package.json'
import { Session } from "bc-react-session";
var loc = '';
const updateLangAttribute = () => {
  document.body.setAttribute('lang', i18n.language);
};

// Listen to language change events and update the lang attribute
i18n.on('languageChanged', updateLangAttribute);
loc = window.location.origin+'/';

if(!IS_LOCALHOST){
  loc += BASE_URL
}
let currentLanguage = 'de'
const { payload } = Session.get("login");
const UserSession = payload;
if (UserSession.status !== undefined && UserSession.status === "loggedin") {
  if (UserSession.DashboardLanguage === 'EN' || UserSession.DashboardLanguage === 'en') {
    currentLanguage = 'en'
  }
}else if (window.location.pathname === '/forgot_password' || window.location.pathname === '/login' || window.location.pathname === '/kunden_beta/revamp/login' || window.location.pathname === '/kunden_beta/revamp/forgot_password') {
  const loginLanguage = localStorage.getItem('login_language');
  if (loginLanguage && (loginLanguage === 'en' || loginLanguage === 'de')) {
    currentLanguage = loginLanguage;
  }
}

i18n
.use(Backend)
.use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // lng: "de",
    lng: currentLanguage,
    fallbackLng: "de", // use en if detected lng is not available
    supportedLngs: ['de', 'en'],
    cleanCode:false,
    debug: false,
    load: 'currentOnly',
    backend: {
        loadPath: loc+'locales/{{lng}}/translation.json?v='+packageJson.version,
      },
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;