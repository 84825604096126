import { Helmet } from "react-helmet-async";
import { useEffect } from "react";
import TagManager from "react-gtm-module";
import smartlookClient from "smartlook-client";
import { IS_LOCALHOST, arrSigninAsAdminAuthId } from "../../config/Constants";
import { SERVER_URL } from "../../services/ServiceConstants";
import "../../styles/index.scss";
import "../../styles/not-found.scss";
import "../../styles/figma-buttons.scss";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Session } from "bc-react-session";
import { DotLottiePlayer } from "wms-react-lottie-player";
import innovationLottie from "../../assets/images/404.lottie";
import { ReactComponent as LogoIcon } from "../../assets/images/jobspreader_logo_alt.svg";
import { ReactComponent as LogoTextVG } from '../../assets/icons/Type=LogoText.svg';

export default function NotFound() {
  const tagManagerArgs = {
    gtmId: "GTM-5MLBD6W",
  };
  const { t } = useTranslation();
  const UserSession = Session.get("login").payload;
  let backToLink = "/kunden_beta/revamp/login";
  console.log(IS_LOCALHOST)
  let backToLinkText = t("text_404_login_btn");

  if (UserSession.status && UserSession.status === "loggedin") {
    backToLink = IS_LOCALHOST ? "/" : "/kunden_beta/switchToBeta?targetPage=login";
    backToLinkText = t("text_404_dashboard_btn");
  } else i18n.changeLanguage("de");

  useEffect(() => {
    //adjust zoom level and remove styles
    document.body.removeAttribute("style");
    document.body.removeAttribute("class");
    if (!IS_LOCALHOST) {
      const signInAsAdmin =
        UserSession.UserName === "Demo" ||
        UserSession.LoginByMasterPass === true ||
        (UserSession.intAuthID !== undefined && arrSigninAsAdminAuthId.includes(parseInt(UserSession.intAuthID)));

      var SubUserIDSm = "";
      if (UserSession.SubUserID !== "") {
        SubUserIDSm = "_" + UserSession.SubUserID;
      }
      var UserIDSm = "";
      if (UserSession.UserID !== "") {
        UserIDSm = "_" + UserSession.UserID;
      }
      var FUseridSm = "JSPF_" + UserIDSm + SubUserIDSm;

      if (!signInAsAdmin) {
        if (
          UserSession !== undefined &&
          UserSession.UIPreferences !== undefined &&
          UserSession.UIPreferences.cookie_consent !== undefined
        ) {
          if (UserSession.UIPreferences.cookie_consent[0].ga === "true") {
            TagManager.initialize(tagManagerArgs);
          }
          if (UserSession.UIPreferences.cookie_consent[0].smartlook === "true") {
            smartlookClient.init("112ac909729bc3c2d08114cc8d9cdd143629b76d", {
              cookies: false,
              region: "eu",
              advancedNetwork: {
                allowedUrls: [new RegExp("^" + SERVER_URL)],
                // allowedHeaders: ['x-custom-header'],
                websockets: true,
              },
            });
            smartlookClient.identify(FUseridSm);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {t("404_page_title")} - {t("jobspreader_board_text")}
        </title>
      </Helmet>
      <div className={"content-wrapper-404"}>
        <div className="frame_404">
          <div className="logo">
            <a href={backToLink}>
              <svg className="group" width="49" height="70" viewBox="0 0 49 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Group 235">
                  <path id="Vector" d="M40.5075 0.5H8.49949C4.07977 0.5 0.5 4.01 0.5 8.35V46.65C0.5 50.99 4.07977 54.5 8.49949 54.5H28.4982L39.7475 69.5V54.5H40.5075C44.9272 54.5 48.507 50.99 48.507 46.65V8.34999C48.507 4.00999 44.9272 0.499994 40.5075 0.5Z" fill="#1C2D3B" />
                  <g id="Group">
                    <path id="Vector_2" d="M11.8598 19.85L13.5998 10.47L15.3598 19.85H17.3798L19.6898 7.5H17.5698L16.3798 16.87L14.6698 7.5H12.5298L10.8398 16.87L9.63977 7.5H7.50977L9.83977 19.85H11.8598Z" fill="white" />
                    <path id="Vector_3" d="M23.5993 19.9901C24.7393 19.9901 25.4793 19.5401 25.9793 18.9301C26.8093 17.9701 26.8793 16.6101 26.8793 13.6801C26.8793 10.7501 26.8093 9.40012 25.9793 8.43012C25.4793 7.82012 24.7393 7.37012 23.5993 7.37012C22.4593 7.37012 21.7193 7.82012 21.2193 8.43012C20.4093 9.41012 20.3193 10.7501 20.3193 13.6801C20.3193 16.6101 20.4093 17.9401 21.2193 18.9301C21.7193 19.5401 22.4593 19.9901 23.5993 19.9901ZM22.6793 9.58012C22.8493 9.30012 23.1293 9.11012 23.5893 9.11012C24.0493 9.11012 24.3493 9.30012 24.5193 9.58012C24.8793 10.1701 24.9293 11.4201 24.9293 13.6701C24.9293 15.9201 24.8793 17.1701 24.5193 17.7601C24.3493 18.0301 24.0493 18.2301 23.5893 18.2301C23.1293 18.2301 22.8493 18.0401 22.6793 17.7601C22.3193 17.1701 22.2493 15.9201 22.2493 13.6701C22.2493 11.4201 22.3193 10.1701 22.6793 9.58012Z" fill="white" />
                    <path id="Vector_4" d="M33.4481 18.1H30.2181V7.5H28.2881V19.85H33.4481V18.1Z" fill="white" />
                    <path id="Vector_5" d="M39.7184 18.1H36.4984V7.5H34.5684V19.85H39.7184V18.1Z" fill="white" />
                    <path id="Vector_6" d="M16.3398 33.6801V21.3301H14.5798L12.2198 27.8201L9.83984 21.3301H8.08984V33.6801H10.0198V26.0701L11.6598 31.0501H12.7398L14.4098 25.9801V33.6801H16.3398Z" fill="white" />
                    <path id="Vector_7" d="M20.0091 21.3301H18.0791V33.6801H20.0091V21.3301Z" fill="white" />
                    <path id="Vector_8" d="M21.5693 21.3301V33.6801H26.7393V31.9301H23.4993V21.3301H21.5693Z" fill="white" />
                    <path id="Vector_9" d="M30.8083 22.9399C31.2783 22.9399 31.5483 23.1099 31.7183 23.3899C31.9283 23.6799 31.9983 24.1399 32.0083 24.5899H33.9383C33.9083 23.4999 33.5783 22.5699 32.9583 21.9899C32.4083 21.4699 31.7283 21.1899 30.7983 21.1899C29.6583 21.1899 28.9183 21.6399 28.4183 22.2499C27.6083 23.2199 27.5283 24.5799 27.5283 27.4999C27.5283 30.4199 27.6083 31.7799 28.4183 32.7499C28.9183 33.3599 29.6583 33.8099 30.7983 33.8099C31.7283 33.8099 32.3683 33.5599 32.9183 33.0299C33.5483 32.4599 33.9083 31.5099 33.9383 30.3999H32.0083C31.9983 30.8499 31.9183 31.2999 31.7183 31.6099C31.5483 31.8799 31.2683 32.0599 30.8083 32.0599C30.3483 32.0599 30.0683 31.8699 29.8983 31.5899C29.5183 30.9999 29.4683 29.7499 29.4683 27.4899C29.4683 25.2299 29.5183 23.9799 29.8983 23.3899C30.0683 23.1099 30.3483 22.9199 30.8083 22.9199V22.9399Z" fill="white" />
                    <path id="Vector_10" d="M39.578 21.3301V26.5401H37.178V21.3301H35.248V33.6801H37.178V28.2901H39.578V33.6801H41.508V21.3301H39.578Z" fill="white" />
                    <path id="Vector_11" d="M12.4702 40.6201L11.0202 40.0301C10.1502 39.6901 10.0002 39.3701 10.0002 38.3701C10.0002 37.8301 10.1002 37.4201 10.3302 37.1601C10.5202 36.9501 10.7402 36.8101 11.1602 36.8101C11.5802 36.8101 11.8502 36.9401 12.0202 37.1701C12.2802 37.5001 12.3102 37.9301 12.3102 38.4901H14.2402C14.2402 37.3101 14.0102 36.4301 13.3702 35.8001C12.8702 35.3201 12.1402 35.0601 11.1602 35.0601C10.2802 35.0601 9.5902 35.3301 9.0502 35.8001C8.3802 36.4101 8.0502 37.3101 8.0502 38.4201C8.0502 40.3201 8.5202 41.0001 9.7602 41.5201L11.2302 42.1301C12.1802 42.5101 12.3902 42.8401 12.3902 44.1601C12.3902 44.8201 12.3202 45.2301 12.0602 45.5301C11.8702 45.7601 11.5602 45.8801 11.1302 45.8801C10.7002 45.8801 10.4002 45.7401 10.2302 45.5501C9.9502 45.2401 9.8802 44.8201 9.8802 44.1601H7.9502C7.9502 45.4601 8.16019 46.2201 8.7402 46.8101C9.25019 47.3301 10.0302 47.6301 11.1202 47.6301C12.2102 47.6301 13.0202 47.3001 13.5202 46.8101C14.1402 46.2001 14.3102 45.3501 14.3102 44.0801C14.3102 42.0001 13.9102 41.2201 12.4402 40.6101L12.4702 40.6201Z" fill="white" />
                    <path id="Vector_12" d="M17.9389 35.1602L14.9189 47.5102H16.9489L17.4289 45.0602H20.2789L20.7789 47.5102H22.8089L19.8189 35.1602C19.8189 35.1602 17.9389 35.1602 17.9389 35.1602ZM17.8089 43.3102L18.8589 38.1402L19.9089 43.3102H17.7989H17.8089Z" fill="white" />
                    <path id="Vector_13" d="M27.8388 44.5802C27.8388 45.4602 27.2988 45.9002 26.6488 45.9002C25.9988 45.9002 25.4388 45.4602 25.4388 44.5802V35.1602H23.5088V44.4802C23.5088 45.4202 23.8188 46.2002 24.3588 46.7502C24.9488 47.3602 25.7488 47.6502 26.6588 47.6502C27.5688 47.6502 28.3488 47.3602 28.9288 46.7502C29.4588 46.1902 29.7788 45.4202 29.7788 44.4802V35.1602H27.8488V44.5802H27.8388Z" fill="white" />
                  </g>
                  <path id="Vector_14" d="M38.7778 37.5C37.7678 37.5 36.9478 38.01 36.5078 38.83C36.0678 38.01 35.2478 37.5 34.2378 37.5C32.7878 37.5 31.5078 38.73 31.5078 40.17C31.5078 43.45 36.5078 45.5 36.5078 45.5C36.5078 45.5 41.5078 43.44 41.5078 40.17C41.5078 38.73 40.2278 37.5 38.7778 37.5Z" fill="#F82C51" />
                </g>
              </svg>

              <svg className="img" width="252" height="39" viewBox="0 0 252 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Group">
                  <path id="Vector" d="M9.72585 19.4802C9.72585 23.0202 7.65585 25.3402 4.63586 25.3402C3.77586 25.3402 2.96586 25.2302 2.21586 25.0302L0.505859 29.7802C1.90586 30.3402 3.46586 30.6402 5.17586 30.6402C11.7859 30.6402 16.0759 25.6602 16.0759 19.5002V0.490234H9.72586V19.4702L9.72585 19.4802Z" fill="#1C2D3B" />
                  <path id="Vector_2" d="M32.1542 8.35034C25.3347 8.31033 20.835 13.3303 20.835 19.5003C20.835 25.6703 25.2947 30.6503 32.1542 30.6503C39.0138 30.6503 43.4835 25.6603 43.4835 19.5003C43.4835 13.3403 39.0338 8.35034 32.1542 8.35034ZM32.1542 25.5803C28.9044 25.5803 26.8546 23.0703 26.8546 19.5003C26.8146 15.9303 28.8744 13.4203 32.1542 13.4203C35.434 13.4203 37.4539 15.9103 37.4539 19.5003C37.4539 23.0903 35.404 25.5803 32.1542 25.5803Z" fill="#1C2D3B" />
                  <path id="Vector_3" d="M60.2221 8.35C57.9023 8.35 55.4824 9.34 53.4525 11.24V0.5H47.5029V30.06H53.4525V27.77C55.5024 29.69 57.8823 30.66 60.2921 30.66C65.7318 30.66 69.4915 25.72 69.4915 19.51H69.5015C69.4915 13.3 65.7518 8.34 60.2121 8.35999L60.2221 8.35ZM59.0222 25.65C57.3023 25.65 55.1724 24.82 53.4426 23.12V15.88C55.1724 14.17 57.3123 13.35 59.0222 13.35C61.852 13.35 63.4919 15.89 63.4819 19.5C63.4819 23.11 61.852 25.65 59.0222 25.65Z" fill="#1C2D3B" />
                  <path id="Vector_4" d="M82.2004 16.9701C79.0006 16.1801 78.2307 15.8001 78.3207 14.7601C78.4307 13.3901 79.7906 12.7901 81.5905 12.7901C83.7203 12.7901 86.1002 13.4201 87.5301 14.6801L89.7999 11.0501V11.0301C87.6201 9.07008 84.2103 8.33008 81.3505 8.33008C76.8908 8.33008 72.4611 10.1101 72.4611 15.1301C72.4611 19.1201 75.2209 20.2401 80.5805 21.6001C83.2204 22.2801 84.7003 22.5401 84.6803 23.9201C84.6603 25.2901 83.1904 26.1801 80.7005 26.1801C78.2607 26.1801 75.5209 25.3501 74.1509 23.6701L71.8711 27.2901C73.781 29.5201 77.5107 30.6401 80.9805 30.6401C85.3402 30.6401 90.5399 28.8901 90.5599 23.7701C90.5799 18.8801 85.8702 17.8401 82.2004 16.9601L82.2004 16.9701Z" fill="#1C2D3B" />
                  <path id="Vector_5" d="M106.209 8.3501C103.89 8.3501 101.47 9.3401 99.4399 11.2401V8.9501H93.4902V38.5101H99.4399V27.8901C101.5 29.7501 103.92 30.6601 106.149 30.6601C111.399 30.6601 115.489 26.1701 115.499 19.5101C115.489 13.3001 111.749 8.34009 106.209 8.36008L106.209 8.3501ZM105.02 25.7101C103.23 25.7301 101.2 24.7301 99.4498 23.0101V15.8801C101.18 14.1701 103.31 13.3501 105.029 13.3501C107.859 13.3501 109.499 15.8901 109.489 19.5001C109.499 23.4901 107.629 25.6901 105.02 25.7101Z" fill="#1C2D3B" />
                  <path id="Vector_6" d="M133.538 8.32031C130.838 8.32031 127.728 9.52031 125.268 11.8403V8.94031H119.318V30.0703H125.268V16.6003C127.488 14.7903 130.138 13.8503 132.458 13.8503C132.688 13.8503 132.918 13.8503 133.138 13.8703L134.108 8.35031C133.918 8.33031 133.728 8.33031 133.538 8.33031L133.538 8.32031Z" fill="#1C2D3B" />
                  <path id="Vector_7" d="M146.896 8.35016C140.407 8.33016 136.127 13.3302 136.127 19.5002C136.127 25.6702 140.447 30.6502 147.006 30.6502C150.056 30.6502 153.036 29.6302 155.446 27.8002L154.056 24.0902C151.796 25.1802 149.586 25.8302 147.556 25.8302C144.796 25.8302 142.837 24.1802 142.317 21.2702C142.297 21.1502 142.277 21.0502 142.257 20.9302H157.176C157.196 20.5902 157.206 20.2702 157.206 19.9402H157.226C157.256 12.9802 153.456 8.35016 146.906 8.35016L146.896 8.35016ZM142.337 17.3202C142.867 14.8002 144.496 13.1602 147.016 13.1602C149.726 13.1602 150.956 14.7802 151.326 17.3202H142.337Z" fill="#1C2D3B" />
                  <path id="Vector_8" d="M176.085 11.2101C173.965 9.2201 171.525 8.3501 169.235 8.3501C163.835 8.3501 160.056 13.2901 160.056 19.5001C160.056 25.7101 163.845 30.6501 169.235 30.6501C171.525 30.6501 173.955 29.7801 176.085 27.7901V30.0501H182.034V8.9401H176.085V11.2101ZM176.085 23.1501C174.415 24.8101 172.245 25.6501 170.565 25.6501C167.725 25.6501 166.105 23.2301 166.105 19.5001C166.105 15.7701 167.785 13.3501 170.565 13.3501C172.245 13.3501 174.405 14.1901 176.085 15.8501V23.1501Z" fill="#1C2D3B" />
                  <path id="Vector_9" d="M201.943 11.2402C199.863 9.28023 197.393 8.35023 195.163 8.35023C189.644 8.33023 185.884 13.2902 185.884 19.5002C185.884 25.7102 189.664 30.6502 195.063 30.6502C197.363 30.6502 199.803 29.7702 201.933 27.7602V30.0502H207.882V0.490234H201.933V11.2302L201.943 11.2402ZM201.953 23.1202C200.263 24.8002 198.083 25.6502 196.413 25.6502C193.533 25.6502 191.913 23.1602 191.933 19.4902C191.913 15.8202 193.513 13.3402 196.353 13.3402C198.133 13.3402 200.263 14.1902 201.953 15.8702V23.1202Z" fill="#1C2D3B" />
                  <path id="Vector_10" d="M222.721 8.35016C216.232 8.33016 211.952 13.3302 211.952 19.5002C211.952 25.6702 216.282 30.6502 222.831 30.6502C225.881 30.6502 228.861 29.6302 231.271 27.8002L229.881 24.0902C227.621 25.1802 225.411 25.8302 223.381 25.8302C220.622 25.8302 218.662 24.1802 218.142 21.2702C218.122 21.1502 218.102 21.0502 218.082 20.9302H233.001C233.021 20.5902 233.051 19.9402 233.051 19.9402C233.081 12.9802 229.291 8.35016 222.731 8.35016L222.721 8.35016ZM218.162 17.3202C218.692 14.8002 220.322 13.1602 222.841 13.1602C225.551 13.1602 226.781 14.7802 227.141 17.3202H218.152H218.162Z" fill="#1C2D3B" />
                  <path id="Vector_11" d="M250.921 8.32031C248.221 8.32031 245.111 9.52031 242.651 11.8403V8.94031H236.701V30.0703H242.651V16.6003C244.871 14.7903 247.521 13.8503 249.841 13.8503C250.071 13.8503 250.301 13.8503 250.521 13.8703L251.501 8.35031C251.311 8.33031 250.931 8.32031 250.931 8.32031H250.921Z" fill="#1C2D3B" />
                </g>
              </svg>

            </a>
          </div>
          <DotLottiePlayer src={innovationLottie} loop autoplay className="copy-of-sau" />
          <div className="div">
            <p className="ups-die-seite-ist">
              <span className="text-wrapper">{t("text_404_line_1")}</span>
              {!(UserSession.status && UserSession.status === "loggedin") && (
                <>
                  <br />
                  <span className="span">{t("text_404_line_2")}</span>
                </>
              )}
            </p>
            <a href={backToLink}>
              <button className="figma-btn fg-primary fg-large">
                <span>{backToLinkText}</span>
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
