/**
 * React entrypoint
 *
 * File updated for 404 implementation, no changes in
 * any other session or login related code.
 */
import log from "loglevel";
import React, { useEffect, useLayoutEffect } from "react";
import ReactDOM from "react-dom/client";
import { Session } from "bc-react-session";

import App from "./App";
import AppLogin from "./AppLogin";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import NotFound from "./components/inc/NotFound";

import { LOGIN_URL, SERVER_URL, SITE_URL } from "./services/ServiceConstants";
import { IS_LOCALHOST, BASE_URL, arrSigninAsAdminAuthId } from "./config/Constants";
import { HelmetProvider } from "react-helmet-async";
import { RouteMap, SettingRoutes, InnovationLabRoutes, URLAccessibleWithoutSession } from "./config/RouteMap";
import { getLoginData, getPortfolioData, getSessionData } from "./services";
import { WMSContextProvider, useWMSContext } from "./wmsContext";

import "./styles/index.scss";

import ForgotPassword from "./components/ForgotPassword"

import Unsubscribe from "./components/Unsubscribe"


import { AESGCM_DECRYPT } from "./utils/aes-256-GCM";

/****** original password *******/

// import loginDEV from "./dev/mainAccountJson.json";
// import loginDEV from './dev/masterAccount.json';
// import loginDEV from './dev/subAccount.json'; //use with theuserid
// import loginDEV from './dev/demoAccount.json';

/****** master password *********/

import loginDEV from "./dev/mainAccountMasterPass.json";

// import loginDEV from "./dev/masterAccountDB.json";
// import loginDEV from './dev/masterAccountMasterPass.json';
// import loginDEV from './dev/subAccountMasterPass.json'; //use with theuserid

/******* older imports **********/
// import loginDEV from '../src/dev/realAccountTestJson.json';
// import loginDEV from '../src/dev/superAdminJson.json';
// import loginDEV from '../src/dev/signInAsAccount.json';

if (IS_LOCALHOST) log.enableAll();
else if (localStorage.getItem("wmsEnableConsoleLogs")) log.enableAll();
else log.disableAll();

log.debug("Index.js Load");


function WMSRootApp() {
  const { is404, set404 } = useWMSContext();
  const {hasSession,setHasSession} = useWMSContext()
  const searchparams = new URLSearchParams(window.location.search);
  const { payload } = Session.get("login");
  const UserSession = payload;


  let currentPath = (window.location.origin + window.location.pathname).replace(SITE_URL, "");
  if(currentPath === SERVER_URL){
    currentPath = '';
  }
  const redirectPath = window.location.pathname + window.location.search;
  const validPath = Object.values({...RouteMap, ...SettingRoutes, ...InnovationLabRoutes,...URLAccessibleWithoutSession}).indexOf(currentPath) > -1;
  const params = new URLSearchParams(window.location.search);
  const subUserID = params.get("theuserid");
  let theuserid = "";

  if (subUserID != null) {
    theuserid = "theuserid=" + subUserID;
  }

  useLayoutEffect(()=>{

        
      if((searchparams.get('theuserid') === undefined || searchparams.get('theuserid') === '' || searchparams.get('theuserid') === null) && UserSession.intAuthID !== undefined &&
      arrSigninAsAdminAuthId.includes(parseInt(UserSession.intAuthID))){
          //if removed 'theuserid' from the URL, redirect to dashboard with theuserid
          let theuserIdbtoa = btoa(parseInt(UserSession.SignInUserID));
          if(theuserIdbtoa !== null){
            log.debug("theuser id null case",theuserIdbtoa);
            window.location.href = `dashboard?theuserid=`+theuserIdbtoa;
            return false;
          }
      }else if(searchparams.get('theuserid') !== undefined && searchparams.get('theuserid') !== '' && searchparams.get('theuserid') !== null){
        if(hasSession === false){
          // If 'theuserid', set the session flag
          window.localStorage.setItem('hasSessionProvider',true)
          setHasSession(true);
        }  
      }

      if(searchparams.get('theuserid') !== undefined && searchparams.get('theuserid') !== '' && searchparams.get('theuserid') !== null){
        if(UserSession !== undefined && UserSession.SignInUserID !== undefined && UserSession.SignInUserID){
          log.debug("theuser id null case 2",searchparams.get('theuserid'));
          if(UserSession.SignInUserID !== atob(searchparams.get('theuserid'))){
            // session of multiple admin login override
            Session.destroy('login');
            log.debug("theuser id null case 2.1",searchparams.get('theuserid'));

            window.location.href = currentPath+`?theuserid=`+(searchparams.get('theuserid'));
          }else if(!arrSigninAsAdminAuthId.includes(parseInt(UserSession.intAuthID))){
            // session of main account override  
            log.debug("theuser id null case 2.2",searchparams.get('theuserid'));

            Session.destroy('login');
            window.location.href = currentPath+`?theuserid=`+(searchparams.get('theuserid'));
          }
        }
      }
   
      if(!hasSession){
        Session.destroy("login");
      }
      if (!IS_LOCALHOST && (hasSession || theuserid !== '')) {
        
        // Login Service Start
        getSessionData(theuserid).then(
          (result) => {
            console.log("Login is already Set");
            console.log("Session is set", result);
          },
          (error) => {
            log.debug("Root index.js getSessionData Service Error", error);
            if(error ==='NoSession'){
              setHasSession(false);
              Session.destroy("login");
              window.location = LOGIN_URL;
            }
            if(error === "Invalid theuserid" ){
              set404(true);
            }
            if(validPath && Object.values({...URLAccessibleWithoutSession}).indexOf(currentPath) > -1) {
              if(UserSession !== undefined && UserSession.DefaultLandingPageURL !== undefined && UserSession.DefaultLandingPageURL){
                window.location = UserSession.DefaultLandingPageURL;
              }else{
                setHasSession(false);
                window.location = LOGIN_URL;
              }
              // return false;
            }
          }
        );
      }
      if(!hasSession && currentPath !== RouteMap.forgot_password && currentPath !== RouteMap.unsubscribe && !theuserid){
        
        if(IS_LOCALHOST){
          
        }
      
        if(!hasSession && validPath && currentPath === 'logout') {
          window.location.href = LOGIN_URL;
          
        }else if(!hasSession && validPath && currentPath !== 'login' && params.get("redirect") === null) {
          window.location = LOGIN_URL +"?redirect=" + btoa(redirectPath);
        }
        
      }
      
      if(hasSession){
        if(validPath && currentPath === 'login') {
          if(UserSession !== undefined && UserSession.DefaultLandingPageURL !== undefined && UserSession.DefaultLandingPageURL){
          window.location = UserSession.DefaultLandingPageURL;
          }
        }
      }
      
    
  }, []);
  
  // return <HelmetProvider>{((is404 || !validPath ? <NotFound /> :<App theuserid={theuserid} />)) }</HelmetProvider>;
  // return <HelmetProvider>{is404 || !validPath ? <NotFound /> : (hasSession?<App theuserid={theuserid} />:<AppLogin theuserid={theuserid} />)}</HelmetProvider>;
  return (
    <HelmetProvider>
      {currentPath === RouteMap.forgot_password ? (
        <ForgotPassword />
        ) : currentPath === RouteMap.unsubscribe ? (
          <Unsubscribe />
        ) : is404 || !validPath ? (
        <NotFound />
      ) : hasSession || theuserid ? (
        <App theuserid={theuserid} />
      ) : (
        <AppLogin theuserid={theuserid} />
      )}
    </HelmetProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <WMSContextProvider>
    <WMSRootApp />
  </WMSContextProvider>
);
//React.StrictMode calls App.js 2 times.

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(log.debug))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
