import log from "loglevel";
import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getPortfolioData } from "../../../services";
import { Session } from "bc-react-session";
import "../../../styles/HelpModal.css";
import { ReactComponent as HelpIconSVG } from "../../../assets/icons/IconHelp.svg";

import { Button, Dropdown, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import ModalBodyScrollIcon from "./scroll-down";
import Loader from "./loader-block";

export default function HelpModal({ setModal, modal, helpId, defaultHelp, isolated, modalHelpText, helpTextButton = true, seperateHook = false }) {
  const { t } = useTranslation();
  const { payload } = Session.get("login");
  const UserSession = payload;

  const [helpData, setHelpData] = useState(true);
  const [helpHeading, setHelpHeading] = useState(true);
  const [helpBody, setHelpBody] = useState(true);
  const [activeHelpId, setActiveHelpId] = useState(defaultHelp);
  const [NextHelpId, setNextHelpId] = useState(false);
  const [PrevHelpId, setPrevHelpId] = useState(false);

  
  const handleDropdownChange = (currHelpId) => {
    setPrevHelpId(false);
    // log.debug(typeof currHelpId)
    // log.debug(helpData.sequence)
    // log.debug(helpData.sequence.indexOf(parseInt(currHelpId)))
    if(helpData.sequence.indexOf(parseInt(currHelpId)) > 0){
      setPrevHelpId(helpData.sequence[helpData.sequence.indexOf(parseInt(currHelpId)) - 1]);
    }
    setActiveHelpId(parseInt(helpData.data[currHelpId].ID));

    setNextHelpId(helpData.data[currHelpId]["NextHelpID"] !== null && helpData.data[currHelpId]["NextHelpID"] !== "0" ? helpData.data[currHelpId]["NextHelpID"] : false);
    setHelpHeading(helpData.data[currHelpId]["Title"]);
    setHelpBody(helpData.data[currHelpId]["Message"]);
  };
  const handleHelpModal = (result, postDataConversionBlock) => {
    // const UserSession = payload;
    const params = new URLSearchParams(window.location.search);
  const paramsUserID = params.get("theuserid");
  let theuserid = UserSession.UserID;

  if (paramsUserID != null) {
    theuserid = paramsUserID;
  }
  setModal(true);

    getPortfolioData({ getHelpAPI: 1, helpId: helpId, isolated: isolated, userID: theuserid, sessionData: UserSession }).then(
      (result) => {
        log.debug("Myjobs > getHelpAPI > res=", result);
        if (result.status === "success") {
          setActiveHelpId(defaultHelp);
          setPrevHelpId(false);
          setNextHelpId(false);
          setHelpHeading(result.title);
          setHelpBody(result.message);
          if (result.data) {
            setNextHelpId(result.data[helpId]["NextHelpID"] !== null ? result.data[helpId]["NextHelpID"] : false);
            if(defaultHelp !== helpId)
            {
              setHelpHeading(result.data[defaultHelp]["Title"]);
              setHelpBody(result.data[defaultHelp]["Message"]);
              setPrevHelpId(result.sequence[result.sequence.indexOf(defaultHelp)-1])
              setNextHelpId(result.data[defaultHelp]["NextHelpID"] !== null ? result.data[defaultHelp]["NextHelpID"] : false);
            }
          }
          setHelpData({
            data: result.data,
            hasChild: result.hasChild,
            message: result.message,
            sequence: result.sequence,
          });
        }


      },
      (error) => {
        log.debug(error);
      }
    );
    
    
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if(modal && params.get("previewjcdhelpid") !== null){
      handleHelpModal();
    }
    if (seperateHook && modal) {
      handleHelpModal();
    }
    // eslint-disable-next-line
  }, [modal]);
  return (
    <>
      {!helpTextButton ? (
        <>
          <span style={{ cursor: "pointer" }} onClick={handleHelpModal}>
            {modalHelpText}
          </span>
        </>
      ) : (
        <div className="helpBtn ">
  <OverlayTrigger
    key={1}
    delay={{ hide: 450, show: 300 }}
    overlay={(props) => (
              <Tooltip {...props} className="tooltip-action" placement="left">
                <div className="text-justify">{t("mjclone_help_text")} </div>
      </Tooltip>
    )}
    placement="bottom"
    html="true"
  >
            <button
              type="button"
              className="btn jscdga_pf_jobs_help  hovertooltip p-2 btn-primary"
              style={{ height: "32px" }}
              onClick={handleHelpModal}
            >
      <HelpIconSVG className="mr-2 helpIconAlignment" />
      {modalHelpText}
    </button>
  </OverlayTrigger>

</div>
      )}
<Modal
        className={"help-modal"}
        dialogClassName={"helpDailog"}
        id={"messageModal"}
        onClose={() => {
          setModal(false);
        }}
    show={modal}
        onHide={() => {
          setModal(false);
        }}
  >
        <Modal.Header closeButton style={{ minHeight: "74px", backgroundColor: "#EFF0FF", alignItems: "center" }}>
          <Modal.Title>
            <HelpDropdown
              helpData={helpData}
              activeHelpId={activeHelpId}
              t={t}
              handleDropdownChange={handleDropdownChange}
            />
            <span>{helpHeading === true ? t("pf_jscd_help_default_heading") : helpHeading}</span>
          </Modal.Title>
        </Modal.Header>
        {helpBody === true  ? <ModalBodyScrollIcon key={'111'} >
          <Loader incremental style={{ width: "120px", height: "120px" }} />
        </ModalBodyScrollIcon> : <ModalBodyScrollIcon key={helpBody}> <div dangerouslySetInnerHTML={{ __html: helpBody }} /> </ModalBodyScrollIcon>}
        
        <Modal.Footer className={PrevHelpId !== false ? "justify-content-between" : ""}>
          {PrevHelpId !== false ? (
            <Button
              variant="outline-primary"
              onClick={() => {
                handleDropdownChange(PrevHelpId);
              }}
            >
              {t("type_previous_advice_text")}
            </Button>
          ) : (
            <></>
          )}

          {activeHelpId === helpId && helpBody !== true  ? (
            <Button
              variant="outline-primary"
              onClick={() => {
                setModal(false);
              }}
            >
              {t("previous_text")}
            </Button>
          ) : (
            <></>
          )}

          {NextHelpId !== false && isolated !== true ? (
            <Button
              variant="primary"
              className="primary_action_button"
              onClick={() => {
                handleDropdownChange(NextHelpId);
              }}
            >
              {t("next_text")}
            </Button>
          ) : (
            <Button
              variant="primary"
              className="primary_action_button"
              onClick={() => {
                setModal(false);
              }}
            >
              {t("text_close_modal")}
            </Button>
          )}
      {/* <Button variant="primary" className="finalSubmitSettings pull-right " onClick={handleSubmitSettings} >{t('md_beta_save_settings')}</Button> */}
    </Modal.Footer>
  </Modal>

  </>
  );
}

export function HelpDropdown({ helpData, activeHelpId, t, handleDropdownChange }) {

  // const { payload } = Session.get('login');
  // const UserSession = payload;
  // const { t } = useTranslation();
  let dropdownSubUser = helpData.hasChild ? true : false;


  // Set/Update Nav Dropdown Language Btn
  if (dropdownSubUser) {
    // undropdown(0);
    return (
      <>
        <Dropdown className={"d-inline mr-3"}>
          <Dropdown.Toggle variant="outline-primary" className={""} aria-expanded="false" style={{ padding: "0.55rem 1.375rem", lineHeight: "1", marginLeft: "unset"}}>
            {t("type_advice_text")} ({helpData.sequence.indexOf(activeHelpId) + 1}/{helpData.sequence.length})
          </Dropdown.Toggle>
          <Dropdown.Menu className="navbar-dropdown no-before">
            {
              // Object.keys
              helpData.sequence.map(function (element) {
                log.debug("help", element);
                if (element) {
                  return (
                    <Dropdown.Item
                    className={`${activeHelpId === element ? "active" : ""}`}
                      onClick={() => handleDropdownChange(element)}
                      >
                      {helpData.sequence.indexOf(element) + 1}. {helpData.data[element]["Title"]}
                    </Dropdown.Item>
                  );
                } else {
                  return <></>;
                }
              })
            }
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }
}
