import React, { useState, useEffect, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { getLoginData } from '../../services';

export const Carousel = forwardRef((props, ref) => {

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const { i18n } = useTranslation(); 
  const languageSuffix = i18n.language === 'en' ? '_EN' : '_DE';
  const [items, setItems] = useState([]);
  const [allItems, setAllItems] = useState([]); 
  const { t } = useTranslation();

  useEffect(() => {
    let interval;
    if (items.length > 1 && !isPaused) {
     interval = setInterval(() => {
      moveToNextSlide();
    }, 10000); 
    }

    return () => clearInterval(interval); // Clean up on component unmount
  }, [currentIndex,isPaused]);

  const handleMouseEnter = () => {
    setIsPaused(true);  
  };
  
  const handleMouseLeave = () => {
    setIsPaused(false);  
  };
  
  const moveToSlide = (index) => {
    setCurrentIndex(index);
  };

  const moveToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === items.length - 1 ? 0 : prevIndex + 1));
  };

  const isNewTagVisible = (startDate) => {
    const currentDate = new Date();
    const start = new Date(startDate);
    const timeDifference = Math.abs(currentDate - start);
    const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return dayDifference <= 14; // Show if within 14 days from start date
  };
  const logoSrc = i18n.language === 'en' 
    ? require("../../assets/images/login/logo-1.svg").default 
    : require("../../assets/images/login/logo-1DE.svg").default;

    useEffect(() => {
      getLoginData({ login_carousel: 1, requestFromOld: 1 })
        .then(result => {
          const filteredItems = result
            .filter(item => item.Status === "Enabled")
            .map(item => ({
              imgSrc_EN: `https://tools.jobspreader.com/manage/images/tools-uploaded/teaser/` + item[`ImageURL_EN`],
              mainHeading_EN: item.HeadLine_EN,
              subHeading_EN: item.SubLine_EN,
              link_EN: item.Link_EN,
              imgSrc_DE: `https://tools.jobspreader.com/manage/images/tools-uploaded/teaser/` + item[`ImageURL_DE`],
              mainHeading_DE: item.HeadLine_DE,
              subHeading_DE: item.SubLine_DE,
              link_DE: item.Link_DE,
              newTag: item.NewTag === "Enabled",
              startDate: item.StartDate
            }));
          setAllItems(filteredItems);
  
          const languageSuffix = i18n.language === 'en' ? '_EN' : '_DE';
          const updatedItems = filteredItems.map(item => ({
            imgSrc: item[`imgSrc${languageSuffix}`],
            mainHeading: item[`mainHeading${languageSuffix}`],
            subHeading: item[`subHeading${languageSuffix}`],
            link: item[`link${languageSuffix}`],
            newTag: item.newTag,
            startDate: item.startDate
          }));
          setItems(updatedItems);
        });
    }, []);

    useEffect(() => {
      const languageSuffix = i18n.language === 'en' ? '_EN' : '_DE';
      const updatedItems = allItems.map(item => ({
        imgSrc: item[`imgSrc${languageSuffix}`],
        mainHeading: item[`mainHeading${languageSuffix}`],
        subHeading: item[`subHeading${languageSuffix}`],
        link: item[`link${languageSuffix}`],
        newTag: item.newTag,
        startDate: item.startDate
      }));
      setItems(updatedItems);
  }, [i18n.language]);


  return (
    <div className="login-teaser">
      <img className="logo" src={logoSrc} alt="Logo" />

      <div className="login-carousel-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}  >
        <div className="login-carousel" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
          {items.map((item, index) => (
            <div key={index} className="login-carousel-item">
              <a href={item.link} >
                <div className="product-image-div">
                <img className="product-image" src={item.imgSrc} alt="Product" />
                </div>
                <div className="information">
                  <div className="new-tag" style={{ visibility: item.startDate && isNewTagVisible(item.startDate) ? "visible" : "hidden"}}>
                    <div className="frame">
                    <div className="NEU">{t('login_carousel_new_tag')}</div>
                    </div>
                  </div>
                  <p className="main-heading">{item.mainHeading}</p>
                  <p className="subheading">{item.subHeading}</p>
                </div>
              </a>
            </div>
          ))}
        </div>

        <div className="dot-indicators">
          {items.map((_, index) => (
            <div
              key={index}
              className={`pagination-dot ${currentIndex === index ? "active-dot" : ""}`}
              onClick={() => moveToSlide(index)}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default Carousel;
