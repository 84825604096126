import { DEV } from "../config/Constants";

/**
 * This file is to handle unwanted console logs.
 * It is important to call this file at the beginning of the app.
 */
var console = (function (oldCons) {
  // eslint-disable-next-line no-undef
  // let drop = "true" === process.env.REACT_APP_DROP_CONSOLE ? true : false;
  let drop = DEV ? false : true;
  drop = true;
  let newCons = { ...oldCons };
  window.consol = oldCons; // A quick handle to be able to use consoles on app. Use consol instead of console
  if (drop) {
    // oldCons.log(
    //   "%c               ",
    // );
    oldCons.log(
      "%cStop!",
      "color: red; font-family: sans-serif; font-size: 4.5em; font-weight: bolder; text-shadow: #000 1px 1px;"
    );
    oldCons.log(
      "%c%s",
      "color: red; font-size: 14px; padding: 6px;",
      "CAUTION: This space is only for developers. Please do not copy-paste any scripts here. It can be harmful!"
    );
    newCons.log = function () {};
    newCons.warn = function () {};
    // newCons.error = oldCons.error; // You can retain error logs if required.
  }
  return newCons;
})(window.console);
window.console = console;
