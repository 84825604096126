import React, { useState, useEffect } from 'react';
import Carousel from "./inc/Carousel";
import Footer from "./inc/Footer";
import Language from "./inc/LoginLanguage";
import { getLoginData } from '../services';
import { useTranslation } from 'react-i18next';
import "../styles/Login.scss"; 
import Loader from './blocks/common/loader-block';
import i18n from "i18next";
import { DotLottiePlayer } from "wms-react-lottie-player";
import innovationLottie from "../assets/images/404.lottie";

const ForgotPassword = () => {

  const [hasLoader,setHasLoader]= useState(false)
  const { t } = useTranslation();

  const [emailForgotPassword, setEmailForgotPassword] = useState('');
  const [emailForgotPasswordError, setEmailForgotPasswordError] = useState('');
  const [emailForgotPasswordError2, setEmailForgotPasswordError2] = useState('');
  const [forgotPasswordPage, setForgotPasswordPage] = useState(true);
  const [changePasswordPage, setChangePasswordPage] = useState(false);
  const [changePasswordPageInvalidLink, setChangePasswordPageInvalidLink] = useState('');
  const [changePassword, setChangePassword] = useState('');
  const [changePasswordConfirm, setChangePasswordConfirm] = useState('');
  const [changePasswordError, setChangePasswordError] = useState('');
  const [changePasswordConfirmError, setChangePasswordConfirmError] = useState('');
  const [changePasswordConfirmError2, setChangePasswordConfirmError2] = useState('');
  const [changePasswordSuccess, setChangePasswordSuccess] = useState('');

  const [isPasswordVisible, setIsPasswordVisible] = useState(false); 
  const [isPasswordVisible2, setIsPasswordVisible2] = useState(false); 


  const [showLogo, setShowLogo] = useState(false);
  const searchparams = new URLSearchParams(window.location.search);
  const step = searchparams.get('step');
  const userEmail = searchparams.get('user');
  const verify = searchparams.get('verify');
  const [forgotPasswordMailPage, setForgotPasswordMailPage] = useState(false);
  const [invalidLinkPage,setInvalidLinkPage] = useState(false);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [isHoveredEye, setIsHoveredEye] = useState(false);
  const [isHoveredEye2, setIsHoveredEye2] = useState(false);
  useEffect(() => {
    const handlePageShow = (event) => {
      if (event.persisted) {
        window.location.reload();
      }
    };

    window.addEventListener('pageshow', handlePageShow);

    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, []);

  useEffect(() => {
    if (step === '2') {
      setShowLogo(true);
      let postDataProtFolioTable = { CheckPasswordChangeAPI: 1, verify: verify };
      getLoginData(postDataProtFolioTable).then((portfolioDataRes) => {
        setShowLogo(false);
        if (portfolioDataRes.status === 'success') {
            if (portfolioDataRes.count === 0) {
                setForgotPasswordMailPage(false);
                setForgotPasswordPage(false);
                setChangePasswordPage(false);
                setInvalidLinkPage(true);
                setChangePasswordPageInvalidLink(t('forget_password_link_notvalid_text'));

            }else if (portfolioDataRes.count === 1){
                setForgotPasswordMailPage(false);
                setForgotPasswordPage(false);
                setChangePasswordPage(true);
            }else{
                setForgotPasswordMailPage(false);
                setForgotPasswordPage(true);
                setChangePasswordPage(false);
            }
        } 
      });
    }
  }, [step]);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
  const ForgotPasswordHandler = (postDataProtFolioTable) => {
    setShowLogo(true);
    getLoginData(postDataProtFolioTable).then((portfolioDataRes) => {
      setShowLogo(false);
      if (portfolioDataRes.status === 'success') {
        setForgotPasswordMailPage(true);
        setForgotPasswordPage(false);
      } else if (portfolioDataRes.status === 'fail') {
        setEmailForgotPasswordError2(t('forget_password_step1_email_notfound_message'));
      } else {
        // console.log("Unexpected response received");
      }


    });
  };

  const handleLoginPage = () => {
    setShowLogo(true);
    window.location.href = 'login';
  }
  useEffect(() => {
    if (emailForgotPasswordError) {
      handleForgotPasswordMailPage();
    }
    if(emailForgotPasswordError2){
      setEmailForgotPasswordError2(t('forget_password_step1_email_notfound_message'));
    }

    if(invalidLinkPage){
      setChangePasswordPageInvalidLink(t('forget_password_link_notvalid_text'));
    }

    if(changePasswordConfirmError !=='' || changePasswordConfirmError2 !==''){
      handleCreatePassword()
    }

  }, [i18n.language]);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleForgotPasswordMailPage = () => {
    setEmailForgotPasswordError('');
    if (emailForgotPassword !== '') {
      if (!validateEmail(emailForgotPassword)) {
        setEmailForgotPasswordError(t('login_form_invalid_email_message'));
        return; 
      }

      let postDataProtFolioTable = { forgotPasswordAPI: 1, email: emailForgotPassword };
      ForgotPasswordHandler(postDataProtFolioTable)
    } else {
      setEmailForgotPasswordError(t('login_form_email_required_message'));
      // return;
    }
  }

  const validatePassword = (password) => {
    return {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password)
    };
  };

  const handlePasswordChange = (e) => {
    setChangePasswordConfirmError2('');
    setChangePasswordConfirmError('');
    const newPassword = e.target.value.replace(/\s+/g, '');
    setChangePassword(newPassword);
  
    const validationResults = validatePassword(newPassword);
    setChangePasswordError(validationResults);
  };

  const passwordValidationCriteria = [
    { key: 'length', text: t('login_create_pass_min_8') },
    { key: 'uppercase', text: t('login_create_pass_uppercase') },
    { key: 'lowercase', text: t('login_create_pass_lowercase') },
    { key: 'number', text: t('login_create_pass_number') }
  ];

  const handleCreatePassword = (e) => {
  

    if (changePassword !== '' && changePasswordConfirm !== '') {
        if(changePassword !== changePasswordConfirm){
            // setTimeout(() => {
            //     setShowLogo(false);
            //   }, 500);
        setChangePasswordConfirmError(t('password_match_error_msg'));
        }else{
          
        const validationResults = validatePassword(changePassword);
        const allCriteriaMet = Object.values(validationResults).every((result) => result === true);
        if (!allCriteriaMet) {
          return;
        }
        setChangePasswordConfirmError2('');
        setChangePasswordConfirmError('');
        setShowLogo(true);
        let postDataProtFolioTable = { SetPasswordAPI: 1, verify: verify ,txtNewPassword:changePassword,txtConfirmNewPassword:changePasswordConfirm};
        getLoginData(postDataProtFolioTable).then((portfolioDataRes) => {
            setShowLogo(false);
            if (portfolioDataRes.status === 'success') {
                setChangePasswordSuccess('1');
            } else if(portfolioDataRes.status === 'fail') {
            if(portfolioDataRes.code === 1){
                setChangePasswordConfirmError(t('password_match_error_msg'));
            }else{
                setForgotPasswordMailPage(true);
                setForgotPasswordPage(false);
                setChangePasswordPage(false);
                setChangePasswordPageInvalidLink(t('forget_password_link_notvalid_text'));
            }
            }
        });

        }
    }else{
        // setTimeout(() => {
        //     setShowLogo(false);
        //   }, 500);
          const emptyErr = t('header_index_login_form_required_message');
          if (changePassword === '') {
            setChangePasswordConfirmError2(emptyErr);
          }
          if (changePasswordConfirm === '') {
            setChangePasswordConfirmError(emptyErr);
          }
    }
  };
  const handleEmailChange = (e) => {
    setEmailForgotPassword(e.target.value); 
    setEmailForgotPasswordError('');
    setEmailForgotPasswordError2('');
  };

  const handelconfirmPass = (e) => {
    const newCPassword = e.target.value.replace(/\s+/g, '');
    setChangePasswordConfirm(newCPassword); 
    setChangePasswordConfirmError('');
  };

  const calculateGap = (height) => {
    if (height >= 900) {
      return 90; 
    }else if (height >= 800) {
      return 35; 
    }else{
      return 90;
    } 
  
  };
  const loginGap = calculateGap(viewportHeight);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible); 
  };

  const togglePasswordVisibility2 = () => {
    setIsPasswordVisible2(!isPasswordVisible2); 
  };

  const getLogoSrc = () => {
    if(i18n.language === 'en'){
     return require(`../assets/images/login/logo-1.svg`).default;
    }else{
     return require(`../assets/images/login/logo-1DE.svg`).default;
    }
 };
 const getImageSrc = (isHovered, isPasswordVisible) => {
  if (isHovered) {
    return isPasswordVisible 
      ? require("../assets/images/login/showpassword-hover.svg").default 
      : require("../assets/images/login/showpassword2-hover.svg").default;
  }
  return isPasswordVisible 
    ? require("../assets/images/login/showpassword.svg").default 
    : require("../assets/images/login/showpassword2.svg").default;
};


  return (
    <>
    <div className='pageWrapper'>
      
    <div className="login" style={{ gap: `${loginGap}px` }}>
    {hasLoader?<Loader loadingTimer={0} style={{ width: "120px", height: "120px" }} />:<>

    <div className='container-div-login'>

      {showLogo && (
        <div className="logo-container">
          <img
            className="logo"
            src={getLogoSrc()}
            alt="Logo"
          />
        </div>
      )}
      <Carousel />
      <div className={`welcome-container-forgot-password`}>
        {/* <GoogleLogin onSuccess={googleResponseMessage} onError={googleErrorMessage} /> */}
        <div className='topDiv'></div>
        {/* Forgot password */}
        {forgotPasswordPage && (
        <div className={`forgot-password`}>
          <div className="container">
            <div className="login-components">
              <div className="back-to-login" onClick={handleLoginPage}>
                <svg className="arrow-back" xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none"  >
                <path d="M12.5 20L4.5 12L12.5 4L13.925 5.4L8.325 11H20.5V13H8.325L13.925 18.6L12.5 20Z" className="arrow-path" fill="#778189" />
                </svg>
                <div className="text-wrapper cursor-pointer" >{t('forgot_password_back_to_login')}</div>
              </div>
            </div>
            <div className="container-wrapper">
              <div className="div">
                <div className="reset-link-message">
                  <div className="instructions-title">{t('forgot_password_head')}</div>
                  <p className="instructions">{t('forgot_password_sub_head')}</p>
                </div>
                <div className="div forgot-upper">
                  <div className="div-2">
                    <div className="form-field-container">
                      <div className="text-wrapper">{t('login_email_address')}*</div>
                      <div className={`input-container ${emailForgotPasswordError ? 'error-border' : ''}`}>
                        <input  autoComplete="off"   type="email" onKeyPress={(e) => { if (e.key === 'Enter') handleForgotPasswordMailPage(); }} className="email-input" placeholder={t('login_email_address_placeholder')} value={emailForgotPassword} onChange={handleEmailChange}  />
                        <img className="img" src={emailForgotPasswordError ?require("../assets/images/login/iconaction-10-error.svg").default:require("../assets/images/login/iconaction-10.svg").default} />
                      </div>
                      {emailForgotPasswordError && <span className="error-message error-msg" dangerouslySetInnerHTML={{ __html: emailForgotPasswordError }} />}
                      {emailForgotPasswordError2 && <span className="error-message noerror-msg" dangerouslySetInnerHTML={{ __html: emailForgotPasswordError2 }} />}
                    </div>
                  </div>
                </div>

                <div className="div">
                  <button className="submit-button-text-wrapper" onClick={(e) => { handleForgotPasswordMailPage() }}><span className="submit-button-text">{t('forgot_password_sign_in_btn')}</span></button>
                </div>
                <div className="forgot-password-bottom"></div>
              </div>
            </div>
          </div>
        </div>
        )}

        {/* Forgot password Mail Sent*/}
        {forgotPasswordMailPage && (
        <div className={`forgot-password`}>
          <div className="container">
            {/* <div className="forgot-password-header"></div> */}
            <div className="login-components">
              <div className="back-to-login" onClick={handleLoginPage}>
                <svg className="arrow-back" xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none"  >
                <path d="M12.5 20L4.5 12L12.5 4L13.925 5.4L8.325 11H20.5V13H8.325L13.925 18.6L12.5 20Z" className="arrow-path" fill="#778189" />
                </svg>
                <div className="text-wrapper cursor-pointer" >{t('forgot_password_back_to_login')}</div>
              </div>
            </div>
            <div className="container-wrapper">
              <div className="div">
                <div className="reset-link-message height190">
                  <div className="instructions-title">{changePasswordPageInvalidLink !== '' ? (<span dangerouslySetInnerHTML={{ __html: changePasswordPageInvalidLink }}></span>) : (<>{t('forgot_password_mail_head')}</>)}</div>
                  {changePasswordPageInvalidLink === '' && (<p className="instructions">{t('forgot_password_mail_sub_head')}</p>)}
                </div>
                <div className="div forgot-upper">

                <div className="div">
                  <div className="div-2">
                    {changePasswordPageInvalidLink === '' && (
                      <div className="form-field-container">

                        <div className="text-wrapper">{t('login_email_address')}*</div>
                        <div className={`input-container ${emailForgotPasswordError ? 'error-border' : ''}`} >
                          <input  autoComplete="off" type="email" onKeyPress={(e) => { if (e.key === 'Enter') handleForgotPasswordMailPage(); }} className="email-input" placeholder={t('login_email_address_placeholder')} value={emailForgotPassword} onChange={handleEmailChange} />
                          <img className="img" src={emailForgotPasswordError ?require("../assets/images/login/iconaction-10-error.svg").default:require("../assets/images/login/iconaction-10.svg").default} />
                        </div>
                        {emailForgotPasswordError && <span className="error-message error-msg" dangerouslySetInnerHTML={{ __html: emailForgotPasswordError }} />}
                        {emailForgotPasswordError2 && <span className="error-message noerror-msg" dangerouslySetInnerHTML={{ __html: emailForgotPasswordError2 }} />}
                      </div>)}
                  </div>
                </div>
                </div>

                <div className="div">
                  {changePasswordPageInvalidLink === '' && (
                    <button className="submit-button-text-wrapper" onClick={(e) => { handleForgotPasswordMailPage() }}><span className="submit-button-text">{t('forgot_password_mail_sign_in_btn')}</span></button>
                  )}
                </div>
                <div className="forgot-password-bottom"></div>
              </div>
            </div>
          </div>
        </div>
        )}

        {/* Change password */}
        {changePasswordPage && (
          <div className="forgot-password">
            <div className="container">
              {/* <div className="forgot-password-header"></div> */}
              <div className="login-components">
              <div className="back-to-login" onClick={handleLoginPage}>
                <svg className="arrow-back" xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none"  >
                <path d="M12.5 20L4.5 12L12.5 4L13.925 5.4L8.325 11H20.5V13H8.325L13.925 18.6L12.5 20Z" className="arrow-path" fill="#778189" />
                </svg>
                <div className="text-wrapper cursor-pointer" >{t('forgot_password_back_to_login')}</div>
              </div>
              </div>
              <div className="container-wrapper">
                <div className={`div changePasswordDiv ${i18n.language === 'en'?'':'DEgapChangepass'}`}>
                  <div className="">
                    <div className="instructions-title titleChangeP">{t('change_password_mail_head')}</div>
                    {changePasswordSuccess && (<p className="instructions">{t('forget_password_change_success_message')}</p>)}

                  </div>
                  <div className="div changePasswordDiv12">
                    <div className="div-2 upper-form">
                    {!changePasswordSuccess && (
                      <div className="form-field-container">
                        <div className='change-password-container'>
                        <div className="text-wrapper">{t('login_password')}*</div>
                        <div className={`input-container ${changePasswordConfirmError2 ? 'error-border' : ''} ${changePassword && changePassword !== '' &&(
                          (!changePasswordError.length || 
                          !changePasswordError.uppercase || 
                          !changePasswordError.lowercase || 
                          !changePasswordError.number )) ? 'error-border' : ''}`}>
                          <input autoComplete="off" onKeyPress={(e) => { if (e.key === 'Enter') handleCreatePassword(); }} type={isPasswordVisible ? 'text' : 'password'} className="email-input" placeholder={t('login_password_placeholder')} value={changePassword} onChange={handlePasswordChange} />
                          <img className="img" src={ changePassword && changePassword !== '' &&(
                          !changePasswordError.length || 
                          !changePasswordError.uppercase || 
                          !changePasswordError.lowercase || 
                          !changePasswordError.number) || changePasswordConfirmError2
                          ?require("../assets/images/login/iconaction-11-error.svg").default:require("../assets/images/login/iconaction-11.svg").default} />                                             
                         {changePassword && changePassword !== '' && ( 
                         <img className="img-right" 
                         src={getImageSrc(isHoveredEye, isPasswordVisible)}  
                         onMouseEnter={() => setIsHoveredEye(true)} onMouseLeave={() => setIsHoveredEye(false)}
                         onClick={togglePasswordVisibility} />
                         )}
                        </div>
                        <div className='changepasswordvalidationDiv'>
                        {changePassword && changePassword.length > 0 && (
                          <>
                          {passwordValidationCriteria.map((criteria, index) => (
                            <p key={index} className="change-password-error">
                              <img
                                className="icon"
                                src={require(changePasswordError[criteria.key] ? '../assets/images/login/set-password-tick.svg' : '../assets/images/login/set-password-cross.svg').default}
                                alt="icon"
                              />
                              <span className={  changePasswordError[criteria.key] ?'noerror-msg':'error-msg'}> {criteria.text}</span>
                            </p>
                          ))}
                        </>
                        )}
                        </div>
                        {changePasswordConfirmError2 && <span className="error-message error-msg" style={{marginTop:'-4px'}}>{changePasswordConfirmError2}</span>}

                        </div>
                        <div className='confirm-password-container'>
                        <div className="text-wrapper">{t('login_password_confirm')}*</div>
                        <div className={`input-container ${changePasswordConfirmError ? 'error-border' : ''}`}>
                          <input autoComplete="off" onKeyPress={(e) => { if (e.key === 'Enter') handleCreatePassword(); }} type={isPasswordVisible2 ? 'text' : 'password'} className="email-input" placeholder={t('login_password_confirm')} value={changePasswordConfirm} onChange={handelconfirmPass} />
                          <img className="img" src={changePasswordConfirmError
                          ?require("../assets/images/login/iconaction-11-error.svg").default:require("../assets/images/login/iconaction-11.svg").default} />                           {changePasswordConfirm && changePasswordConfirm !== '' && ( 
                          <img className="img-right"
                          //  src={isPasswordVisible2 ? require("../assets/images/login/showpassword.svg").default:require("../assets/images/login/showpassword2.svg").default}
                            src={getImageSrc(isHoveredEye2, isPasswordVisible2)} 
                            onMouseEnter={() => setIsHoveredEye2(true)} 
                            onMouseLeave={() => setIsHoveredEye2(false)}
                            onClick={togglePasswordVisibility2} />
                          )}
                          </div>
                          <div className='changepasswordvalidationDiv'></div>
                          {changePasswordConfirmError && <span className="error-message error-msg" style={{marginTop:'-4px'}}>{changePasswordConfirmError}</span>}

                          </div>
                      </div>
                       )}
                    </div>
                  </div>
                  <div className="div">
                  {!changePasswordSuccess && (
                    <button className="submit-button-text-wrapper" onClick={(e) => { handleCreatePassword() }}>
                      <span className="submit-button-text">{t('forgot_password_change_pass_btn')}</span>
                    </button>
                  )}
                  </div>
                  <div className="forgot-password-bottom"></div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Invalid Link*/}
        {invalidLinkPage && (
        <div className={`forgot-password`}>
          <div className="container">
            <div className="login-components">
              <div className="back-to-login" onClick={handleLoginPage}>
                <svg className="arrow-back" xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none"  >
                <path d="M12.5 20L4.5 12L12.5 4L13.925 5.4L8.325 11H20.5V13H8.325L13.925 18.6L12.5 20Z" className="arrow-path" fill="#778189" />
                </svg>
                <div className="text-wrapper cursor-pointer" >{t('forgot_password_back_to_login')}</div>
              </div>
            </div>
            <div className="container-wrapper">
              <div className="div">
                <div className="reset-link-message">
                  <div className="instructions-title"><span dangerouslySetInnerHTML={{ __html: changePasswordPageInvalidLink }}></span></div>
                </div>
                <div className="div">
                  <div className="div-2">
                  <DotLottiePlayer src={innovationLottie} loop autoplay className="copy-of-sau" />
                  </div>
                </div>
                <div className="div">
                  {changePasswordPageInvalidLink === '' && (
                    <button className="submit-button-text-wrapper" onClick={(e) => { handleForgotPasswordMailPage() }}><span className="submit-button-text">{t('forgot_password_mail_sign_in_btn')}</span></button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        )}
        <Language />
      </div>
      </div>
      <Footer /></>}
    </div>
    </div>
    </>
  );
};

export default ForgotPassword;
