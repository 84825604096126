import React, { useState, useEffect } from 'react';
import Carousel from "./inc/Carousel";
import Footer from "./inc/Footer";
import Language from "./inc/LoginLanguage";
import { getLoginData } from '../services';
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import { Session } from "bc-react-session";

import "../styles/Login.scss"; 

import { useWMSContext } from '../wmsContext';
import { AESGCM_DECRYPT, AESGCM_ENCRYPT } from '../utils/aes-256-GCM';
import { useGoogleLogin } from '@react-oauth/google';

import axios from 'axios';
import Loader from './blocks/common/loader-block';

import { IS_LOCALHOST, MS_CLIENT_ID } from '../config/Constants';
import { SITE_URL } from '../services/ServiceConstants';
import { UserEvents } from '../config/UserEvents';
import { recordUserEvent } from './blocks/common/record-user-event';

const MSLOGIN_LINK = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id="+MS_CLIENT_ID+"&scope=openid%20email&response_type=token&redirect_uri="+encodeURIComponent(SITE_URL+'login')


const LoginPage = () => {

  const [hasLoader,setHasLoader]= useState(false)
  
  function popupwindow(url, title, w, h) {
    var left = (window.screen.width / 2) - (w / 2);
    var top = (window.screen.height / 2) - (h / 2);
    return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
  }

  const { t } = useTranslation();
  const { hasSession, setHasSession } = useWMSContext()
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [isHoveredGoogle, setIsHoveredGoogle] = useState(false);
  const [isHoveredMicrosoft, setIsHoveredMicrosoft] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loginAttempt, setLoginAttempt] = useState('');
  const [emailError, setEmailError] = useState('');

  const [passwordError, setPasswordError] = useState('');
  const [rememberMe, setRememberMe] = useState(false);


  const [showLogo, setShowLogo] = useState(false);
  const [lockedAccount,setLockedAccount] = useState(false);
  const [saveLoginAttempt,setSaveLoginAttempt] = useState('');
  const [saveLoginAttemptMsgKey,setSaveLoginAttemptMsgKey] = useState('');

  const params = new URLSearchParams(window.location.search);
  const redirectURL = params.get("redirect");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false); 

  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [isHoveredEye, setIsHoveredEye] = useState(false);


  // Login with Google
  const [user, setUser] = useState([]);

  const login =useGoogleLogin({
    onSuccess: (codeResponse) => {
      console.log('Google Signin', codeResponse);
      setUser(codeResponse)
    },
    onError: (error) => console.log('Login Failed:', error)
  });

  
  useEffect(() => {
      if (user.access_token !== undefined) {
        axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: 'application/json'
            }
          })
          .then((res) => {
            // console.log('Google Signin', res.data)
            
            let postdata = { SignInWithSocial: 'google-login-credentials', userData: res.data ,currentLanguage:i18n.language,redirect:redirectURL }
            getSessionData(postdata)
           
            // return false;
          })
          .catch((err) => console.log(err));
      }
  },[user]);
  // Login with Google


  useEffect(() => {

   
    if(hasSession === false) 
    {
      Session.destroy('login'); 
    
    } 
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let locationHash = window.location.hash;
  let queryArr = locationHash.replace('#access_token', 'access_token').split('&');
  
  useEffect(()=>{
    
    if(queryArr !== undefined){
      let queryParams = {};
      for (var q = 0; q < queryArr.length; q++) {
        var qArr = queryArr[q].split('=');
        queryParams[qArr[0]] = qArr[1];
      }
      
      console.log('MS Signin', queryParams)
      if(queryParams.access_token !== undefined){
        setHasLoader(true);
        let postdata = { SignInWithSocial: 'ms-login-credentials', ms_data: queryParams ,currentLanguage:i18n.language,redirect:redirectURL}
        getSessionData(postdata,true)
      }
    }
    
  },[queryArr])

  // Login with Microsoft

  const getSessionData = (postdata,loginWithMS = false) => {
    setShowLogo(true);
    getLoginData(postdata)
      .then(
        (portfolioDataRes) => {
          // console.log('Google Signin Social Login', portfolioDataRes)
          // console.log(portfolioDataRes, 'check this portfolio res')
          setShowLogo(false);
          if (portfolioDataRes.status === 'success') {
            localStorage.removeItem('login');

            setHasSession(true);
            
            var decryptedString = AESGCM_DECRYPT(portfolioDataRes.sessionData);
            let decryptedArr = JSON.parse(decryptedString);
            if (decryptedArr.status === 'loggedin') {
              i18n.changeLanguage((decryptedArr.DashboardLanguage).toLowerCase())
              Session.start('login', { expiration: decryptedArr.expirationReact ? decryptedArr.expirationReact : 2000 });
              Session.setPayload(decryptedArr)
            }
      
            let currentRedirectUrl = portfolioDataRes.page;
            if(postdata.redirect !== null){
              currentRedirectUrl = atob(postdata.redirect);
            }
            if(!IS_LOCALHOST){
              currentRedirectUrl = '/kunden_beta/loginWithReact?sessionData='+AESGCM_ENCRYPT(JSON.stringify({email:decryptedArr.UserEmail,redirectURL:currentRedirectUrl}));
            }else{
              currentRedirectUrl = 'dashboard';
            }
            
            if(loginWithMS){
              window.opener.location = currentRedirectUrl;
              window.close();
            }else{
              window.location.href = currentRedirectUrl;
            }
          } else if (portfolioDataRes.status === 'fail') {
            setHasSession(false)

            if(portfolioDataRes.LogStatus === 'Locked')
            {
              setLockedAccount(true);
            }else{
              setErrorMessage(t('login_form_wrong_pass_error_message'));
              if (portfolioDataRes.loginAttempt !== '') {
                const messageKey = portfolioDataRes.loginAttempt === 1 
                    ? 'login_form_wrong_pass_limit_message_single' 
                    : 'login_form_wrong_pass_limit_message';
            
                setLoginAttempt(` - ${portfolioDataRes.loginAttempt} ${t(messageKey)}`);

                setSaveLoginAttempt(portfolioDataRes.loginAttempt);
                setSaveLoginAttemptMsgKey(messageKey);
              }
            }
          } else {
            setHasSession(false)
            console.log("Unexpected response received");
          }
        },
      )
  }

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  useEffect(() => {
    if (emailError || passwordError) {
      handleLogin()
    }
    if(errorMessage){
      setErrorMessage(t('login_form_wrong_pass_error_message'));
    }

    if(loginAttempt){
      setLoginAttempt(` - ${saveLoginAttempt} ${t(saveLoginAttemptMsgKey)}`);
    }

  }, [i18n.language]);

  const handleLogin = () => {
    setEmailError('');
    setPasswordError('');
    setErrorMessage('');
    setLoginAttempt('');
    
    if (password !== '' && email !== '') {
      if (!validateEmail(email)) {
        setEmailError(t('login_form_invalid_email_message'));
        return;
      }
      let postDataProtFolioTable = { loginAPI: 1, password: password, email: email,rememberMe:rememberMe,currentLanguage:i18n.language,redirect:redirectURL };
      
      getSessionData(postDataProtFolioTable)
    } else {
      if (email === '') {
        setEmailError(t('login_form_email_required_message'));
      }else{
        if (!validateEmail(email)) {
          setEmailError(t('login_form_invalid_email_message'));
        }
      }
      if (password === '') {
        setPasswordError(t('login_form_password_required_message'));
      }
    }
  }

  const handleForgotPasswordPage = () => {
    recordUserEvent({
        session_data: {'nosession':'true'},
        eventName: UserEvents.link_forget_password_page_login
    });
    setShowLogo(true);
    window.location.href = 'forgot_password';
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value); 
    setEmailError('');
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value); 
    setPasswordError('');
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible); 
  };

  const calculateGap = (height) => {
    if (height >= 900) {
      return 90; 
    }else if (height >= 800) {
      return 35; 
    }else{
      return 90;
    } 
  };

  const loginGap = calculateGap(viewportHeight);

  const getLogoSrc = () => {
      if(i18n.language === 'en'){
      return require(`../assets/images/login/logo-1.svg`).default;
      }else{
      return require(`../assets/images/login/logo-1DE.svg`).default;
      }
  };

  const getImageSrc = () => {
    if (isHoveredEye) {
        return isPasswordVisible 
            ? require("../assets/images/login/showpassword-hover.svg").default 
            : require("../assets/images/login/showpassword2-hover.svg").default;
    }
    return isPasswordVisible 
        ? require("../assets/images/login/showpassword.svg").default 
        : require("../assets/images/login/showpassword2.svg").default;
  };
  return (
    <>
    <div className='pageWrapper'>    
    <div className="login" style={{ gap: `${loginGap}px` }}>
    {hasLoader?<Loader loadingTimer={0} style={{ width: "120px", height: "120px" }} />:<>
     <div className='container-div-login'>
      {showLogo && (
        <div className="logo-container">
          <img
            className="logo"
            src={getLogoSrc()}
            alt="Logo"
          />
        </div>
      )}
      <Carousel />
      <div className={`welcome-container`}>
        {/* <GoogleLogin onSuccess={googleResponseMessage} onError={googleErrorMessage} /> */}
        <div className='topDiv'></div>
        <div className={`form welcome-containerlogin`}>
          <div className="form-header">
            <div className="form-title">{t('login_heading')}</div>
            <p className="form-subtitle">{t('login_sub_heading')}</p>
          </div>
          <div className='login-form'>
          {!lockedAccount && (
            <div className="div upper-form">
              <div className="div-2">
                <div className="text-wrapper">{t('login_email_address')}*</div>
                <div className={`input-container ${emailError || errorMessage ? 'error-border' : ''}`} >
                  <input type="email" className="email-input" onKeyPress={(e) => { if (e.key === 'Enter' && !lockedAccount) handleLogin(); }}  placeholder={t('login_email_address_placeholder')} value={email} onChange={handleEmailChange} />
                    <img className="img" src={emailError || errorMessage ?require("../assets/images/login/iconaction-10-error.svg").default:require("../assets/images/login/iconaction-10.svg").default} />
                </div>
                {emailError && <span className="error-message error-msg">{emailError}</span>}
              </div>
              <div className="div-2">
                <div className="text-wrapper">{t('login_password')}*</div>
                <div className={`input-container ${passwordError || errorMessage ? 'error-border' : ''}`}>
                  <input type={isPasswordVisible ? 'text' : 'password'} onKeyPress={(e) => { if (e.key === 'Enter' && !lockedAccount) handleLogin(); }}  className="email-input" placeholder={t('login_password_placeholder')} value={password} onChange={handlePasswordChange} />
                    <img className="img" src={passwordError ||errorMessage ?require("../assets/images/login/iconaction-11-error.svg").default:require("../assets/images/login/iconaction-11.svg").default} />
                    {password && password !== '' && ( 
                    <img className="img-right" src={getImageSrc()} onMouseEnter={() => setIsHoveredEye(true)} onMouseLeave={() => setIsHoveredEye(false)} onClick={togglePasswordVisibility} />
                    )}
                </div>
                {passwordError && <span className="error-message error-msg">{passwordError}</span>}
              </div>
              {(errorMessage || loginAttempt) && (
                <div className='loginErrorDIV'>
                  {errorMessage && (
                    <div className="error-message error-msg" dangerouslySetInnerHTML={{ __html: errorMessage }} />
                  )}
                  {loginAttempt && (
                    <div className="login-attempt-message error-msg" dangerouslySetInnerHTML={{ __html: loginAttempt }} />
                  )}
                </div>
              )}
              <div className="options-container">
                  {/* <div className="login-components-2">
                  <div className="checkbox"><input type="checkbox" className="checkbox-default checkbox cursor-pointer" checked={rememberMe} onChange={handleRememberMeChange} /><i className="input-helper"></i></div>
                  <div className="option-description cursor-pointer" onClick={handleRememberMeChange}>{t('login_remember_me')}</div>
                  </div> */}
                <div className="login-components-2"><a className="option-description-2 cursor-pointer" onClick={() => handleForgotPasswordPage()}>{t('login_forgot_password')}</a></div>
              </div>
            </div>
          )}
          {lockedAccount && (
            <div className="div upper-form locked-acc-msg-div">
              <div className='locked-acc-msg' dangerouslySetInnerHTML={{ __html:t('login_locked_acc_msg')}}></div>
            </div>
          )}
            <div className="div bottom-save" >
            <div className="div">
              <button className={`submit-button-text-wrapper${lockedAccount ? ' locked' : ''}`}   onClick={(e) => { if (!lockedAccount) handleLogin(); }} disabled={lockedAccount}><span className="submit-button-text">{t('login_sign_in_btn')}</span></button>
            </div>
            <div className="separator">
              <div className="separator-line"></div>
              <div className="option-description">{t('login_or')}</div>
              <div className="separator-line"></div>
            </div>
            <div 
              className={`div-wrapper google-div ${lockedAccount ? 'notAllowed' : 'cursor-pointer'}`}
              tabindex="0"
              onMouseEnter={() => setIsHoveredGoogle(true)}
              onFocus={() => setIsHoveredGoogle(true)}
              onBlur={() => setIsHoveredGoogle(false)}
              onMouseLeave={() => setIsHoveredGoogle(false)}
            >
              <div className="div-3" 
              onClick={(e) => { if (!lockedAccount) login(); }}
              >
                <img className="google"
                    src={
                      lockedAccount 
                          ? require("../assets/images/login/google-1-2-disable.svg").default 
                          : (isHoveredGoogle 
                              ? require("../assets/images/login/google_hover.svg").default 
                              : require("../assets/images/login/google-1-2.svg").default)
                    }                 
                />
                <div className={`text-wrapper-2 ${lockedAccount ? 'lockedText' : ''}`} >{t('login_signin_google')}</div>
              </div>
            </div>
            {/* <MsalAuthenticationTemplate interactionType={InteractionType.Popup}> */}
            <div 
              className={`div-wrapper google-div ${lockedAccount ? 'notAllowed' : 'cursor-pointer'}`}
              tabindex="0"
              onMouseEnter={() => setIsHoveredMicrosoft(true)}
              onMouseLeave={() => setIsHoveredMicrosoft(false)}
              onFocus={() => setIsHoveredMicrosoft(true)}
              onBlur={() => setIsHoveredMicrosoft(false)}
              onClick={(e) => { if (!lockedAccount) popupwindow(MSLOGIN_LINK, t('login_with_microsoft_button_text'), 800, 600) }}
            >
              <div className="div-3">
                <img className="google" 
                src={
                  lockedAccount 
                      ? require("../assets/images/login/microsoft_disable.svg").default 
                      : (isHoveredMicrosoft 
                          ? require("../assets/images/login/microsoft_hover.svg").default 
                          : require("../assets/images/login/vector-2.svg").default)
                }           
                />
                <div className={`text-wrapper-2 ${lockedAccount ? 'lockedText' : ''}`}>{t('login_signin_microsoft')}</div>
              </div>
            </div>
            {/* </MsalAuthenticationTemplate> */}
            </div>
          </div>
        </div>

        <Language />
      </div>
      
      </div>
      <Footer /></>}
    </div>
    </div>
    </>
  );
};

export default LoginPage;
