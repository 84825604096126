/**
 * the react-circular-progressbar library requires a lot of css modifications
 * to match the figma design of jobspreader.
 *
 * This component is made to make it easier to re-use.
 *
 * Loading values are passed as props to the component.
 * Alternatively, if not passed, the component will use its own states.
 *
 */

import React, { useState, useEffect, useRef, useCallback } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import "../../../styles/loader-block.scss";

/**
 * Renders a loader component with customizable options.
 * Only style prop is required.
 * 
 * Sample usage:
 *
 * ```jsx
 * <Loader
 *      showLoader={true}               // optional
 *      loaderProgress={progrss}        // optional
 *      setLoaderProgress={setProgress} // optional
 *      loadingText={t("loading_text")} // optional
 *      loadingTimer={5}                // optional
 *      incremental={false}             // optional, has priority over loadingTimer
 *      style={{ width: "100px", height: "100px" }}
 * />
 * ```
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.showLoader - Determines whether to show the loader. Default is true.
 * @param {number} props.loaderProgress - The progress value of the loader. Default is 0.
 * @param {function} props.setLoaderProgress - The function to update the loader progress.
 * @param {string} props.loadingText - The text to display while loading.
 * @param {number} props.loadingTimer - The duration of the loading animation in seconds. Default is 5.
 * @param {boolean} props.incremental - Determines whether the loader progress should increase incrementally. Default is false.
 * @param {Object} props.style - Custom styles for the loader.
 * @returns {JSX.Element} The loader component.
 */
export default function Loader(props) {
  const [localLoaderProgress, setLocalLoaderProgress] = useState(0);
  const {
    showLoader = true,
    loaderProgress = localLoaderProgress,
    setLoaderProgress = setLocalLoaderProgress,
    loadingText = "",
    loadingTimer = 5,
    incremental = false,
    style = {},
  } = props;

  const intervalId = useRef(null);
  /**
   * Clears the current interval if it exists.
   */
  const clearCurrentInterval = useCallback(() => {
    if (intervalId.current !== null) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
  }, []);

  /**
   * Increases the loader progress by one and recursively calls itself until the progress reaches 100.
   * @param {number} progress - The current progress value.
   * @param {number} delay - The delay in milliseconds before increasing the progress.
   */
  const increaseProgress = useCallback(
    (progress, delay) => {
      intervalId.current = setTimeout(() => {
        setLoaderProgress(progress + 1);
        if (progress < 100) {
          increaseProgress(progress + 1, delay + 10);
        }
      }, delay);
    },
    [setLoaderProgress]
  );

  useEffect(() => {
    if (showLoader) {
      setLoaderProgress(0);
      if (!incremental) {
        clearCurrentInterval();
        intervalId.current = setInterval(() => {
          setLoaderProgress((oldProgress) => {
            if (oldProgress >= 100) {
              clearCurrentInterval();
              return oldProgress;
            } else return oldProgress + 1;
          });
        }, loadingTimer * 10);
      } else {
        clearCurrentInterval();
        increaseProgress(0, 1);
      }
    } else {
      clearCurrentInterval();
    }

    // Clean up function
    return clearCurrentInterval;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLoader, loadingTimer, incremental, clearCurrentInterval, increaseProgress]);

  return (
    <>
      {showLoader && (
        <div className="loading-wrapper-parent">
          <div className="loading-wrapper-div">
            <div className="loading-wrapper" style={style}>
              <CircularProgressbar
                value={loaderProgress > 100 ? 100 : loaderProgress}
                text={`${loaderProgress > 100 ? 100 : loaderProgress}%`}
                styles={{
                  path: {
                    stroke: `rgba(60, 131, 188, 1)`,
                    transform: "rotate(0.25turn)",
                    transformOrigin: "center center",
                    transition: incremental ? "stroke-dashoffset 0.5s linear 0s" : "stroke-dashoffset 100ms linear 0s",
                  },
                  text: {
                    fill: "#1C2D3B",
                    fontSize: "24px",
                    fontFamily: "Mulish",
                    fontWeight: "700",
                    transform: "rotate(90deg)",
                    transformOrigin: "center center",
                  },
                }}
              />
            </div>
            {loadingText && <div>{loadingText}</div>}
          </div>
        </div>
      )}
    </>
  );
}
