import log from "loglevel";
import axios from 'axios'
import {  IS_LOCALHOST, SYSTEM_ERROR } from "../config/Constants";
import { AESGCM_ENCRYPT } from '../utils/aes-256-GCM';
import { PF_BASE_URL, SET_PF_BASE_URL,Login_BASE_URL } from "./ServiceConstants";
import { Session } from 'bc-react-session';
/**
 * Comon Function to fetch Data from Server.
 */
export const getPortfolioData = (postData) => {
    // log.debug("portfolioServices > getPortfolioData called...");
    return new Promise((resolve, reject) => {
        try {
            // do an SDK, DB call or API endpoint axios call here and return the promise.
            // Override UserID in case of UserID is missing/wrong
            const { payload } = Session.get('login');
            const UserSession = payload;
            const params = new URLSearchParams(window.location.search);
            const paramsUserID = params.get("theuserid");
            let theuserid = UserSession.UserID;
            
            if(paramsUserID != null){
                theuserid = paramsUserID;
            }
            postData['userID'] = theuserid
            log.debug('Network Request',(postData))
            axios
                .post(PF_BASE_URL, {
                    postData: (IS_LOCALHOST ? JSON.stringify(postData) : AESGCM_ENCRYPT(JSON.stringify(postData))), debug: (IS_LOCALHOST ? true : false) }, { "authorization": "Basic ZGV2d21zOm53SDhSV1NKbnV3N2tLeXZFejNy" })
                .then((res) => {
                    // log.debug("getPortfolioData > axios res=", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    // log.debug("getPortfolioData > axios err=", err);
                    reject("Error in getPortfolioData axios!");
                });
        } catch (error) {
            // console.error("in services > updateLastCwkId, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

/**
 * Comon Function to fetch Data from Server.
 */
export const setPortfolioData = (postData) => {
    // log.debug("portfolioServices > getPortfolioData called...");
    return new Promise((resolve, reject) => {
        try {
            // do an SDK, DB call or API endpoint axios call here and return the promise.
            // Override UserID in case of UserID is missing/wrong
            const { payload } = Session.get('login');
            const UserSession = payload;
            const params = new URLSearchParams(window.location.search);
            const paramsUserID = params.get("theuserid");
            let theuserid = UserSession.UserID;
            
            if(paramsUserID != null){
                theuserid = paramsUserID;
            }
            postData['userID'] = theuserid
            log.debug((postData))
            axios
                .post(SET_PF_BASE_URL, { postData: (IS_LOCALHOST ? JSON.stringify(postData) : AESGCM_ENCRYPT(JSON.stringify(postData))), debug: (IS_LOCALHOST ? true : false) }, { "authorization": "Basic ZGV2d21zOm53SDhSV1NKbnV3N2tLeXZFejNy" })
                .then((res) => {
                    // log.debug("getPortfolioData > axios res=", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    // log.debug("getPortfolioData > axios err=", err);
                    reject("Error in getPortfolioData axios!");
                });
        } catch (error) {
            // console.error("in services > updateLastCwkId, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};

/**
 * Comon Function to save rating data to server.
 */
export const savingRatingData = (postData) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(SET_PF_BASE_URL, { postData: (IS_LOCALHOST ? JSON.stringify(postData) : AESGCM_ENCRYPT(JSON.stringify(postData))), debug: (IS_LOCALHOST ? true : false) }, { "authorization": "Basic ZGV2d21zOm53SDhSV1NKbnV3N2tLeXZFejNy" })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in saving rating data axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};


/**
 * SEA image upload serivce with upload progress
 * 
 * TODO: Later to be merged with getPortfolioService
 * only difference being that this accepts another argument
 */
export const seaPortfolioData = (postData, onUploadProgress) => {
    return new Promise((resolve, reject) => {
        try {
            const UserSession = Session.get("login").payload;
            const params = new URLSearchParams(window.location.search);
            const paramsUserID = params.get("theuserid");
            const theuserid = paramsUserID || UserSession.UserID;

            postData["userID"] = theuserid;

            axios
            .post(
                PF_BASE_URL,
                {
                postData: 
                    IS_LOCALHOST ? 
                        JSON.stringify(postData) : 
                        AESGCM_ENCRYPT(JSON.stringify(postData)),
                debug: 
                    IS_LOCALHOST ? 
                        true : 
                        false,
                },
                {
                onUploadProgress,
                }
            )
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject("Error in uploading SEA file axios!");
            });
        } catch (error) {
        reject(SYSTEM_ERROR);
        }
    });
};

/**
 * Login API endpoint
 */
export const getLoginData = (postData) => {
    return new Promise((resolve, reject) => {
        try {
            
            axios
                .post(Login_BASE_URL, {
                    postData: (IS_LOCALHOST ? JSON.stringify(postData) : AESGCM_ENCRYPT(JSON.stringify(postData))), debug: (IS_LOCALHOST ? true : false) }, { "authorization": "Basic ZGV2d21zOm53SDhSV1NKbnV3N2tLeXZFejNy" })
                .then((res) => {
                    log.debug('Network Request',(postData))
                    log.debug('Network Response',(res.data))
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in getLoginData axios!");
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};