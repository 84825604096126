import { Session } from 'bc-react-session';
import { LOGIN_URL } from '../../services/ServiceConstants';
import { logoutPHP } from '../../services/sessionServices';
import { useWMSContext } from '../../wmsContext';

Session.start('login');
Session.onChange((session) => {
        if (session.expired === true) {
                window.location = LOGIN_URL.replace('login', '')+'logout'; //destroy session in php
        }
});

export default function Logout(){
        const {hasSession,setHasSession} = useWMSContext()
        if(!hasSession){
                window.location = LOGIN_URL;
                return false;
        }
        Session.destroy('login'); // destroy react session
        setHasSession(false)
        window.localStorage.removeItem('login');
        window.location = LOGIN_URL; //destroy session in php
}


// To logout multiple enviounments' 

  