import React, { useState, useEffect } from 'react';

import Footer from "./inc/Footer";
import Language from "./inc/LoginLanguage";
import { getLoginData } from '../services';
import { useTranslation } from 'react-i18next';
import "../styles/Login.scss"; 
import Loader from './blocks/common/loader-block';
import i18n from "i18next";
import { Session } from "bc-react-session";

const Unsubscribe = () => {

  const [hasLoader,setHasLoader]= useState(false)
  const { t } = useTranslation();



  const [showLogo, setShowLogo] = useState(false);
  const searchparams = new URLSearchParams(window.location.search);
  const lang = searchparams.get('lang');
  const cid = searchparams.get('cid');

  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [weeklyCheckbox, setWeeklyCheckbox] = useState(false);
  const [monthlyCheckbox, setMonthlyCheckbox] = useState(false);
  const[backToText, setBackToText] = useState('');
  
  const handleLoginPage = () => {
    setShowLogo(true);
    window.location.href = 'login';
  }
  useEffect(() => {
   

  }, [i18n.language]);

  useEffect(() => {

    const { payload } = Session.get("login");
    const UserSession = payload;
  
    if (UserSession.status === "loggedin") {
      setBackToText(t('login_back_to_js'));
    }else{
      setBackToText(t('forgot_password_back_to_login'));
    }
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };

   
  }, []);


  const calculateGap = (height) => {
    if (height >= 900) {
      return 90; 
    }else if (height >= 800) {
      return 35; 
    }else{
      return 90;
    } 
  
  };
  const loginGap = calculateGap(viewportHeight);

  const handleWeeklyCheck = (e) => {
    setWeeklyCheckbox(!weeklyCheckbox);
  };

  
  const handleMonthlyCheck = (e) => {
    setMonthlyCheckbox(!monthlyCheckbox);
  };

  return (
    <>
    <div className='pageWrapper'>
      
    <div className="login" style={{ gap: `${loginGap}px` }}>
    {hasLoader?<Loader loadingTimer={0} style={{ width: "120px", height: "120px" }} />:<>

    <div className='container-div-login'>

     
      <div className={`welcome-container-forgot-password`}>
        <div className='topDiv'></div>
        <div className={`forgot-password`}>
          <div className="preferences-inner">
      <div className="login-components">
      <div className="back-to-login" onClick={handleLoginPage}>
                <svg className="arrow-back" xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none"  >
                <path d="M12.5 20L4.5 12L12.5 4L13.925 5.4L8.325 11H20.5V13H8.325L13.925 18.6L12.5 20Z" className="arrow-path" fill="#778189" />
                </svg>
                <div className="text-wrapper cursor-pointer" >{backToText}</div>
              </div>
      </div>
      <div className="report-preferences">
      <div className="instructions-title">{t('unsubs_report_preferences')}</div>
        <p className="div">{t('unsubs_report_preferences_desc')}</p>
      </div>
      <div className="div-2">
        <div className="div-3">
          <div className="checkbox">
            <input type="checkbox" className="checkbox-default checkbox cursor-pointer" checked={weeklyCheckbox} onClick={handleWeeklyCheck}  /><i className="input-helper"></i>
          </div>
          <p className="p cursor-pointer" onClick={handleWeeklyCheck} >{t('unsubs_report_preferences_weekly')}</p>
        </div>
        <div className="div-3">
        <div className="checkbox">
        <input type="checkbox" className="checkbox-default checkbox cursor-pointer" checked={monthlyCheckbox} onClick={handleMonthlyCheck}  /><i className="input-helper"></i>
          </div>
          <p className="p cursor-pointer" onClick={handleMonthlyCheck}>{t('unsubs_report_preferences_monthly')}</p>
        </div>
      </div>
      <div className="div-2">
        <div className="buttons"><div className="submit-button-text">{t('unsubs_report_preferences_btn')}</div></div>
      </div>
    </div>
        </div>
       

        <Language />
      </div>
      </div>
      <Footer /></>}
    </div>
    </div>
    </>
  );
};

export default Unsubscribe;
