import log from "loglevel";
import { useParams } from 'react-router-dom';
import { demoLoginSession } from "../../services"
import { IS_LOCALHOST } from '../../config/Constants';
import { SITE_URL } from '../../services/ServiceConstants';
import { AESGCM_ENCRYPT } from "../../utils/aes-256-GCM";
import { Session } from 'bc-react-session';



const DemoSession = () => {

    const { payload } = Session.get('login');
    const UserSession = payload;
    log.debug('export demo session fun', UserSession.UserID);
    const { encUserID } = useParams();

    if (UserSession.UserType === 'SuperAdmin' || UserSession.UserType === 'Admin' || UserSession.UserID === 90 || UserSession.UserID === '400') {
        log.debug('valid user to play demo');
        var usernameUri = decodeURI(encUserID);
        var UserID = atob(usernameUri);
        const encPostData = JSON.stringify({ DemoSessionAPI: 1, newUserID: UserID, sessionData: UserSession });
        demoLoginSession({ SessionAPI: true, 'data': AESGCM_ENCRYPT(encPostData) })
            .then(
                (result) => {
                    log.debug('DemoSession is set', result)
                    window.location = '../';
                },
                (error) => {
                    log.debug('INC DemoSession.js DemoSession Service Error', error)
                    if (!IS_LOCALHOST) {
                        window.location = SITE_URL
                    }
                }
            );

    } else {
        // window.location='../';
    }

}
export default DemoSession;

