import log from "loglevel";
import React from 'react';
import { IS_LOCALHOST } from '../../config/Constants';
import {getSessionData} from "../../services"
import { LOGIN_URL } from '../../services/ServiceConstants';

export default function SessionCheck(props) {
   log.debug('cb atleast');
   // Login Service Start
   getSessionData() 
   .then(
   (result) => { 
         // console.timeEnd("answer time");
         log.debug('Valid Session',result) 
   },
   (error) => {
      log.debug('SessionCheck.js getSessionData Service Error',error) 
      if(!IS_LOCALHOST){
         window.location = LOGIN_URL
      }else{
         // alert('logout');
      } 
   });

   // Login Service End
   return (
      <>
         <span></span>
      </>
   )
}