import log from "loglevel";
import React, { useEffect } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation } from "react-i18next";
import { ReactComponent as SortDownIconSVG } from '../../assets/icons/Icon=SortDown.svg';
import { ReactComponent as SortUpIconSVG } from '../../assets/icons/Icon=SortUp.svg';
import { Session } from 'bc-react-session';

import { ReactComponent as LeftIconSVG } from '../../assets/icons/Icon=Left.svg';
import { ReactComponent as TopIconSVG } from '../../assets/icons/Icon=Top.svg';

import { ReactComponent as RightIconSVG } from '../../assets/icons/Icon=Right.svg';

import { ReactComponent as SettingsIconSVG } from '../../assets/icons/Icon=Settings.svg';

import { ReactComponent as SearchIconSVG } from '../../assets/icons/Icon=Search.svg';
import { ReactComponent as BackIconSVG } from '../../assets/icons/iconBack.svg';
import { ReactComponent as FilterIconSVG } from '../../assets/icons/Icon=Filter.svg';
import { ReactComponent as LogoLoaderDE } from '../../assets/icons/LoaderGermanLogo.svg';
import { ReactComponent as LogoLoaderEN } from '../../assets/icons/LoaderEnglishLogo.svg';
import { ReactComponent as HelpIconSVG } from '../../assets/icons/IconHelp.svg';
import $ from 'jquery';
// import ModalComponent from '../blocks/modal-component';
// import { useState } from 'react';
import Nouislider from "nouislider-react";
import JSDateRangePicker from "./common/date-range-picker";
import i18next from "i18next";
import CreatableSelect from 'react-select/creatable';
import { ReactComponent as RedWarningSVG } from '../../assets/icons/Red Warning Icon.svg';

import HelpModal from "./common/help";
import ModalBodyScrollIcon from "./common/scroll-down";
import { getPortfolioData } from "../../services";
import { recordUserEvent } from "./common/record-user-event";
import { useWMSContext } from "../../wmsContext";
import i18n from "../../i18n";

export default function GotoBlock({ data }) {
  const { t } = useTranslation();
  return (
    <div className="row">
      <div className="col-12 text-center">
        <div className="top-nav-widget m-3">
          <div className="go-to valign-text-middle d-inline">{t("pf_goto")}:</div>
          <a className="btn btn-outline-primary border-1px-primary100 d-inline-block" href="#performance-block">{t('pf_goto_performance_details')}</a>
        </div>
      </div>

    </div>
  )
}
export function HeadingBlock({ data, helpModal, setHelpModal, helpModalId, modalHelpText, link, dataEvent }) {
  
const recordHelpingButtonClick = (e,dataEvent) => {
  e.preventDefault();
  
  let thisHref = e.currentTarget.getAttribute('href');
  const { payload } = Session.get('login');
  const UserSession = payload;
  if(UserSession !== undefined){
    recordUserEvent({
        session_data: UserSession,
        eventName: dataEvent
    });
  }
  window.open(thisHref, '_blank', 'noopener,noreferrer'); 
}
  return (<>
    <div className="row mt-2">
      <div className="col-12  mb-4" >
        <div className="heading-block">
        <div className="rectangle" />
          <div>
            <div className="performance-details headline2" id="performance-block"><span>{data.title}</span>
            </div>
          </div>
        <div className="heading-third-div">
          <div className="rectangle" />
          {helpModalId !== undefined ? <HelpModal setModal={setHelpModal} modal={helpModal} helpId={helpModalId} defaultHelp ={helpModalId} modalHelpText={modalHelpText}/> : link !== undefined ? <a href={link} target="_blank" onClick={e=>recordHelpingButtonClick(e,dataEvent)} ><button type="button" className="figma-btn fg-secondary fg-small"><HelpIconSVG/><span>{modalHelpText}</span></button></a> : <></> }
          </div>
        </div>
      </div>
    </div>
    
  </>
  )
}

export function SortIconBlock({ data, onClickSortHandler }) {
  const { t } = useTranslation();
  return (
    <>
      <OverlayTrigger
        key={1}
        delay={{ hide: 450, show: 300 }}
        overlay={(props) => (
          <Tooltip {...props} className="tooltip-action">
            <div className='text-justify'>{t('tooltip_icon_sort_desc')} </div>
          </Tooltip>
        )}
        placement="bottom"
        html="true"
      >
        <span className="hovertooltip jscdga_myjobs_jobs_filters jobs_filters" data-sortby={data.sortkey} data-order="desc" aria-hidden="true" ><SortDownIconSVG onClick={onClickSortHandler} /></span>
      </OverlayTrigger>
      <OverlayTrigger
        key={2}
        delay={{ hide: 450, show: 300 }}
        overlay={(props) => (
          <Tooltip {...props} className="tooltip-action">
            <div className='text-justify'>{t('tooltip_icon_sort_asc')} </div>
          </Tooltip>
        )}
        placement="bottom"
        html="true"
      >
        <span className="hovertooltip jscdga_myjobs_jobs_filters jobs_filters" data-sortby={data.sortkey} data-order="asc" aria-hidden="true" ><SortUpIconSVG onClick={onClickSortHandler} /></span>
      </OverlayTrigger>
    </>

  )

}

export function ActionSettingsBlock({ data, onSettingsChangeHandler }) {

  const { t } = useTranslation();
  const { payload } = Session.get('login');
  const [show, setShow] = React.useState(false);
  const toggleShow = () => setShow((prev) => setShow(!prev));
  let tempArr = []
  const UserSession = payload;

  const changeVal = (e) => {
    if (e.target.checked === true) {
      if (!tempArr.includes(e.target.value)) {
        tempArr.push(e.target.value);
      }
    }
    else if (e.target.checked === false) {
      if (tempArr.includes(e.target.value)) {
        tempArr.splice(tempArr.indexOf(e.target.value), 1)
      }
    }
  }

  let handleAbort = () => {
    toggleShow()
  }
  let handleSave = () => {
    toggleShow()
  }


  const ChangeSettingsToggle = React.forwardRef(({ children, onClick }, ref) => (

    <Button
      className="btn btn-primary  dropdown-toggle p-2 hovertooltip pull-right"
      id="showColsDropDown"
      to="#"
      data-flip="false"
      ref={ref}
      data-boundary=""
      onClick={(e) => {
        e.preventDefault();
        onClick(e);

      }}
    >
      <SettingsIconSVG className="icon14px" />

    </Button>
  ));

  return <>
    {UserSession.JCDVisiblePages.includes('ChangeJobStatus') && <Dropdown className="d-inline ml-1" show={show} onToggle={(isOpen) => setShow(isOpen)} autoClose="outside">
      <Dropdown.Toggle as={ChangeSettingsToggle}></Dropdown.Toggle>
      <Dropdown.Menu as="ul" className="dropdown-menu-setting" flip={false}>
        <Dropdown.Header as="li" className="text-primary" >
          <h4>{t('mj_filter_customize_tbl_heading')}</h4>
        </Dropdown.Header>
        <Dropdown.Divider as="li" className="mt-0" />
        {data.header !== undefined && data.header.map((item, index) => {
          if (item.checked) {
            tempArr.push(item.colClass)
          }
          if (item.showinSettings) {
            return (<Dropdown.Item as="li" key={'settings_' + index}>
              <div className='form-check form-check-flat m-auto'>
                <label className='form-check-label checkbox' ><input data-id={item.colClass} id={'check_' + item.colClass} type='checkbox' name='cloneJob' className='form-check-input selectAllCheckboxFilter' autoComplete={'off'} defaultValue={item.colClass} defaultChecked={item.checked} onClick={changeVal} /><i className="input-helper"></i>{item.label}</label></div>
            </Dropdown.Item>)
          }else{
            return <div key={'settings_' + index}></div>
          }
          
        })}
        <Dropdown.Divider as="li" className="mt-0" />
        <Dropdown.Item as="li"  >
          <button type="button" onClick={handleAbort} className="btn btn-sm btn-outline-primary btnCancelCustomTable float-right">{t('job_page_click_raise_limit_cancel_text')} </button>
          <button type="button" onClick={() => { onSettingsChangeHandler(); handleSave() }} className="btn btn-sm btn-primary btnApplyCustomTable float-right mr-2 jscdga_myjobs_action_customize_table">{t('mj_filter_customize_tbl_save')}</button>
        </Dropdown.Item>

      </Dropdown.Menu>
    </Dropdown>}
  </>

}

export function TableBackToTopOverlay() {
  const { t } = useTranslation();

  return (
    <div
      className="next-column-button pull-right animFade"
      id="table-scroll-top"
      style={{ position: "fixed", bottom: "100px", right: "100px", display: "none", opacity: "0", zIndex: 8 }}
    >
      <div className="column-nav" style={{ opacity: "0.2" }}>
        <div
          id="btn-back-to-top"
          className="frame-50 border-1px-primary100 d-large"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            backgroundColor: "var(--primary100)",
            cursor: "pointer",
            paddingBottom: "2px",
          }}
        >
          <TopIconSVG />
          <div className="next-columns button" style={{ color: "white", fontSize: "14px" }}>
            {t("table_nav_back_to_top")}
          </div>
        </div>
        <div className="frame-49 table-col-left-button">
          <LeftIconSVG />
        </div>
        <div className="frame-51 table-col-right-button">
          <RightIconSVG />
        </div>
      </div>
    </div>
  );
}

export function NextPrevColumnsBlock() {
  const { t } = useTranslation();
  useEffect(() => {
    const rightBtn = document.querySelectorAll('#right-button, .table-col-right-button');
    const leftBtn = document.querySelectorAll('#left-button, .table-col-left-button');
    const tableWrapper = document.querySelector('#table-wrapper');
    
    setTimeout(() => {
      setNextColumnsScrollButtons();
    }, 1000);

    rightBtn.forEach((button) => { 
      button.addEventListener("click", function (event) {
        tableWrapper.scrollLeft += 50;
        setNextColumnsScrollButtons();
        var offsets = document.getElementsByClassName('onlineCol')[0].getBoundingClientRect();
        var left = offsets.left;
        if(left <= 150 || left <= 400)
        {
          $("#jobGroupsContainer tbody td.onlineCol").css('opacity','0');
        
        }
        event.preventDefault();
      });
    });

    leftBtn.forEach((button) => {
      button.addEventListener("click", function (event) {
        tableWrapper.scrollLeft -= 50;
        setNextColumnsScrollButtons();
        var offsets = document.getElementsByClassName('onlineCol')[0].getBoundingClientRect();
        var left = offsets.left;
        if(left > 150)
        {
          $("#jobGroupsContainer tbody td.onlineCol").css('opacity','1');
          $("#jobGroupsContainer tbody td.pausedCol").css('opacity','1');
        }
        event.preventDefault();
      });
    });

    tableWrapper.addEventListener("scroll", function (event) {
        setNextColumnsScrollButtons()
        event.preventDefault();
    });

    function setNextColumnsScrollButtons() {
        let isBothArrowDisabled  =   true;
        // Scroll is at the left of wrapper
        if (tableWrapper.scrollLeft === 0) {
            leftBtn.forEach((button) => button.classList.add("disabled-button-action"));
        } else {
            leftBtn.forEach((button) => button.classList.remove("disabled-button-action"));
            isBothArrowDisabled =   false;
        }
        // Scroll is at the end of wrapper
        if (tableWrapper.scrollWidth - tableWrapper.offsetWidth - tableWrapper.scrollLeft < 16) {
            rightBtn.forEach((button) => button.classList.add("disabled-button-action"));
        } else {
            rightBtn.forEach((button) => button.classList.remove("disabled-button-action"));
            isBothArrowDisabled =   false;
        }

        document.querySelectorAll('.next-column-button').forEach( e => { e.classList.remove('disabled_tbl_nav'); });
        if( isBothArrowDisabled ){
            document.querySelectorAll('.next-column-button').forEach( e => {
                e.classList.add('disabled_tbl_nav');   
            });
        }
    }

    window.addEventListener("resize", e => {
      // Show/Hide table left right column arrows
      if( typeof setNextColumnsScrollButtons === 'function' ){
        setTimeout(setNextColumnsScrollButtons, 100);
      }
    });

    let resizeObserverTimeout = null;
    const resizeObserver = new ResizeObserver(() => {
      if (resizeObserverTimeout) clearTimeout(resizeObserverTimeout);
        resizeObserverTimeout = setTimeout(setNextColumnsScrollButtons, 100);
    });
    resizeObserver.observe(tableWrapper);

    return () => {
      resizeObserver.disconnect();
    };

    // return () => window.removeEventListener("resize", handleResize);
}, []);
  return <div className="next-column-button pull-right">
    <div className="column-nav">
      <div className="frame-50 border-1px-primary100">
        <div className="next-columns button">{t('pf_level_next_cols')}</div>
      </div>
      <div className="frame-49" id="left-button">
        <LeftIconSVG/>
      </div>
      <div className="frame-51" id="right-button">
        <RightIconSVG />

      </div>
    </div>
  </div>
}



export function BackButtonNavigation({ backButtonData }) {

  return (<ul className="navbar-nav">

    <li className="nav-item dropdown d-none d-lg-flex">
      <a className="back-button-block" href={backButtonData.backButtonLink}>
        <div className="frame-2">
          <div className="frame-139">
            <BackIconSVG />
            <div className="back-to-campaign-portfolio button">
              {backButtonData.backButtonText}</div>
          </div>
        </div>
      </a>
    </li>
  </ul>)
}

export function TableHeader({ arrTableInformation, onClickSortHandler }) {
  // const { t } = useTranslation();
  // const { payload } = Session.get('login');
  // const UserSession = payload;
  const handleSelectAllCheckBox = (e) => {
    if (e.target.checked) {
      log.debug('checked');
      $('.submit_clone').removeClass('disabledButton');
      $('.submit_clone').removeClass('btn-default');
      $('.submit_clone').addClass('btn-primary');
    }
    else {
      log.debug('here');
      $('.submit_clone').addClass('disabledButton');
      $('.submit_clone').addClass('btn-default');
      $('.submit_clone').removeClass('btn-primary');
    }

  }
  return <thead>
    <tr>
      {/* {arrTableInformation.showCheckBoxColumn ?  : <></>} */}
      {arrTableInformation.header !== undefined && arrTableInformation.header.map((item, index) => {
        if (item.customHtml !== undefined) {
          return (item.checked ? <th key={index} className={item.colClass}>{item.customHtml}</th> : '')
        }
        if(item.colClass === 'checkboxCol'){
          return <th className="compareJobsRow compareCol pt-0">
          <div className="form-check form-check-flat" style={{ marginLeft: '3px' }}><label className="form-check-label checkbox" data-placement="right" data-custom-class="tooltip-action" >
            <input type='checkbox' className='form-check-input' id='selectAllCheckbox' onChange={handleSelectAllCheckBox} autoComplete={"off"} /><i className="input-helper"></i>
          </label></div>
        </th>;
        }
        return (item.checked ? <th key={index} className={item.colClass}>{item.hasIcon ? item.icon : '' }{item.label} 
          {item.hasSort ? <SortIconBlock key={index} data={{ sortkey: item.sortKey }} onClickSortHandler={onClickSortHandler} /> : ''}</th> : '')
      })}
    </tr>
  </thead>

}

export function escapeHtml(unsafe) {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}

export const validateDecimal = (event) => {
  return (i18next.language === 'de' ? (event.charCode >= 44 && event.charCode <= 57 && event.charCode !== 46 && event.charCode !== 47 && event.charCode !== 45) : (event.charCode >= 46 && event.charCode <= 57 && event.charCode !== 47));
}

export function ActionTopFilters({ topFilterData, clicksBlockChangeHandler, renderedFrom, portfolioTableHandler, onFilterChangeHandler, portfolioTableData }) {
  const { t } = useTranslation();
  const { payload } = Session.get('login');
  const UserSession = payload;
  log.debug('ActionTopFilter', i18next.language)
  const [show, setShow] = React.useState(false);
  const toggleShow = () => setShow((prev) => setShow(!prev));

  const tempDate = String(topFilterData.startDate).split(".");
  const tempDateEnd = String(topFilterData.endDate).split(".");
  let datePickerData = {
    startDate: topFilterData.startDate,
    endDate: topFilterData.endDate,
    initialStartDate: new Date(tempDate[2], tempDate[1] - 1, tempDate[0]),
    initialEndDate: new Date(tempDateEnd[2], tempDateEnd[1] - 1, tempDateEnd[0]),
    ranges: topFilterData.ranges,
    maxDate: topFilterData.maxDate
  }

  let datePickerComponent = ''
  if (topFilterData.ranges !== undefined) {
    datePickerComponent = <JSDateRangePicker data={datePickerData} clicksBlockChangeHandler={clicksBlockChangeHandler} portfolioTableHandler={portfolioTableHandler} renderedFrom={renderedFrom} portfolioTableData={portfolioTableData} />
  }

  let handleAbort = () => {
    toggleShow()
  }
  const ChangeExportToggle = React.forwardRef(({ children, onClick }, ref) => (

    <Button
      className="btn btn-primary dropdown-toggle p-2 pull-left"
      id="showFilterDropDown"
      to="#"
      data-flip="false"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {/* data-custom-class="tooltip-action" title={t('tooltip_action_job_status')} */}
      <FilterIconSVG className="mr-2 icon14px" fill="var(--primary100)" />
      <span className="d-large" >{t("tooltip_action_filters")}</span>
    </Button>
  ));
  function searchChangeHandler() {

  }
  const handleChange = event => {
    if (event.target.value !== "") {
      $('#closeIcon').show();
    }
    else {
      $('#closeIcon').hide();
    }
  };
  const resetSearch = () => {
    $("#searchtitle").val("");
    $('#closeIcon').hide();
  };
  return <>
    <div className="float-left">
      <div className="input-group searchtitleGroup">
        <div className="input-group-prepend">
          <SearchIconSVG className="align-items-center d-flex ml-2 mr-2" style={{ width: '17px', height: '17px' }} />
        </div>
        <input type="search" name="searchtitle" className="searchtitle border-0" id="searchtitle" defaultValue="" placeholder={t('mj_filter_title_label')} maxLength='512' onChange={handleChange} onKeyUp={searchChangeHandler} />
        <div className="input-group-append cursor-pointer resetTitleIcon" id="closeIcon" style={{ display: 'none', width: '17px', height: '17px' }} onClick={resetSearch}>
          <i className="align-items-center d-flex fa fa-times-circle mr-2"></i>
        </div>
      </div>
      <button id="searchbutton" type="submit" className="btn btn-sm btn-primary  jscdga_myjobs_search_button p-2 d-none"><span className='fa fa-search hovertooltip' title={t("jobs_alljobs_table_searchbutton_label")}></span></button>
    </div>
    <Dropdown className="d-inline mr-1" show={show} onToggle={(isOpen) => setShow(isOpen)} autoClose={false}>
      <Dropdown.Toggle as={ChangeExportToggle}></Dropdown.Toggle>
      <Dropdown.Menu as={'ul'} className="keep-open filterDropdown">
        <Dropdown.Header className="text-primary"><h4>{t('mj_filter_tbl_heading')}</h4></Dropdown.Header>
        <Dropdown.Divider className="mt-0"></Dropdown.Divider>
        <Dropdown.Item id="filterbodyDropDown">
          <div>
            <form autocomplete="off" id="filterform" onSubmit="return false;">
              <div className='row mb-3'>
                <div className='col-12'>
                  <div className="selectedFilter" style={{ display: 'none' }}>
                    <label className='font-weight-bold'>{t('mj_filter_selected_filters_label')}</label>
                    <label className='ml-2 cursor-pointer' onclick="resetActiveFilters()" >{t('mj_filter_clear_selected_filters_label')}<i className='mdi mdi-window-close ml-1'></i></label>
                  </div>
                  <div>
                    <div className="appliedFilter"></div>
                  </div>

                </div>
              </div>
              <div className='row'>
                {/* <!-- First Filter Group --> */}
                <div className='col-12  col-lg-4 pr-md-3 pr-lg-3 firstFilterGroup'>
                  <div className="form-group">
                    <label className='font-weight-bold'>{t("mj_filter_location_label")}:</label>
                    <input id="filter-location" name="filter[location]" className="form-control" placeholder={t("mj_filter_cd_placeholder")} maxLength="100" onKeyUp={searchChangeHandler} />
                  </div>

                  {UserSession.UIPreferences.jcd_custom_dimensions !== undefined && (UserSession.UIPreferences.jcd_custom_dimensions.map((item, itemIndex) => {

                    return <div key={"cd_edit_" + itemIndex}>{Object.keys(item).map((key, index) => {
                      if (item[key].status === 'enable') {
                        if (item[key].value_type === 'string') {
                          return <div className='form-group mt-4' key={'group_' + index}>
                            <label className='font-weight-bold'>{item[key].visible_name.de}:</label>
                            <input id={'filter-' + key} className='form-control' name={'filter[' + key + ']'} placeholder={t('mj_filter_cd_placeholder')} maxLength='100' onKeyUp={searchChangeHandler} />
                          </div>;
                        }
                        if (item[key].value_type === 'array') {
                          return <div className='form-group mt-4' key={'group_' + index}>
                            <label className='font-weight-bold d-block' >{item[key].visible_name.de}:</label>
                            <CreatableSelect
                              className='custom_dimension_filter_array custom_dimension form-control'
                              isMulti={true}
                              style={{ width: '100%' }}
                              onChange={searchChangeHandler}

                            />
                            {/* <select id='filter-$cdKey' data-dimKey={key} className='custom_dimension_filter_array custom_dimension form-control'  name={'filter['+key+'][]'} multiple style={{width:'100%'}} onKeyUp={ searchChangeHandler }>
                                </select> */}
                            <span id={'error_' + key} className='error mt-2 text-danger' style={{ display: 'none' }}>{t('mj_filter_cd_array_error_msg')}</span>
                            <div className='mt-2'>
                              <input type='checkbox' id='filter-$cdKey-check' name={'filter[' + key + '_check]'} onKeyUp={searchChangeHandler} />
                              <label for={'filter-' + key + '-check'} className='cd-check-col'>{t('cd_filter_check_text')}</label>
                            </div>
                          </div>
                        }
                      }
                      return <></>

                    })}</div>
                  }))}



                </div>
                {/* <!-- Second Filter Group --> */}
                <div className='col-12  col-lg-4 pr-md-3 pl-lg-3 secondFilterGroup'>
                  <div className="form-group ">
                    <label className='font-weight-bold d-block'>{t("mj_filter_jqx_label")}:</label>
                    <Nouislider range={{ min: 0, max: 100 }} start={72} connect={[true, false]} className="ul-slider slider-primary mt-5 ml-3" />
                    <input min='0' max='100' id="filter-jqxFrom" name="filter[jqxFrom]" className="form-control d-inline-block" placeholder="0" type="hidden" onKeyUp={searchChangeHandler} />
                    <input min='0' max='100' id="filter-jqxTo" name="filter[jqxTo]" className="form-control d-inline-block" placeholder={t("mj_filter_to_placeholder")} type="hidden" onKeyUp={searchChangeHandler} />
                  </div>
                  <div className="form-group" >
                    <label className='font-weight-bold d-block'>{t("mj_filter_budget_label")}:</label>
                    <input type="text" min='0' step="1" id="filter-budgetFrom" name="filter[budgetFrom]" className="form-control d-inline-block" placeholder="0" onKeyUp={searchChangeHandler} pattern={i18next.language === 'de' ? '[0-9]+([,][0-9]{1,2})?' : '[0-9]+([\\.][0-9]{1,2})?'} onKeypress={validateDecimal} />
                    <input type="text" min='0' step="1" id="filter-budgetTo" name="filter[budgetTo]" className="form-control d-inline-block" placeholder={t("mj_filter_to_placeholder")} onKeyUp={searchChangeHandler} pattern={i18next.language === 'de' ? '[0-9]+([,][0-9]{1,2})?' : '[0-9]+([\\.][0-9]{1,2})?'} onKeypress={validateDecimal} /> <span className='d-inline-block ml-2'>{t('Euro')}</span>
                  </div>
                  <div className="form-group mt-4">
                    <label className='font-weight-bold d-block'>{t("mj_filter_total_budget_label")}:</label>
                    <input type="text" min='0' step="1" id="filter-totalBudgetFrom" name="filter[totalBudgetFrom]" className="form-control d-inline-block" placeholder="0" onKeyUp={searchChangeHandler} pattern={i18next.language === 'de' ? '[0-9]+([,][0-9]{1,2})?' : '[0-9]+([\\.][0-9]{1,2})?'} onKeypress={validateDecimal} />
                    <input type="text" min='0' step="1" id="filter-totalBudgetTo" name="filter[totalBudgetTo]" className="form-control d-inline-block" placeholder={t("mj_filter_to_placeholder")} onKeyUp={searchChangeHandler} pattern={i18next.language === 'de' ? '[0-9]+([,][0-9]{1,2})?' : '[0-9]+([\\.][0-9]{1,2})?'} onKeypress={validateDecimal} /> <span className='d-inline-block ml-2'>{t('Euro')}</span>
                  </div>

                </div>
                {/* <!-- Third Filter Group --> */}
                <div className='col-12 col-lg-4 pr-md-3 pl-lg-3 thirdFilterGroup'>
                  <div className="form-group" style={{ marginBottom: '1.65rem' }}>
                    <label className='font-weight-bold d-block'>{t("mj_filter_click_label")}:</label>
                    <input type="number" min='0' step='1' id="filter-clickFrom" name="filter[clickFrom]" className="form-control d-inline-block" placeholder="0" onkeypress='return event.charCode >= 48 && event.charCode <= 57' onKeyUp={searchChangeHandler} />
                    <input type="number" min='0' step='1' id="filter-clickTo" name="filter[clickTo]" className="form-control d-inline-block" placeholder={t("mj_filter_to_placeholder")} onkeypress='return event.charCode >= 48 && event.charCode <= 57' onKeyUp={searchChangeHandler} />
                    <span className='d-inline-block ml-2'>{t('Clicks')}</span>
                  </div>
                  <div className="form-group mt-4 mb-4">
                    <label className='font-weight-bold d-block'>{t("mj_filter_total_clicks_label")}:</label>
                    <input type="number" min='0' step='1' id="filter-totalClicksFrom" name="filter[totalClicksFrom]" className="form-control d-inline-block" placeholder="0" pattern={i18next.language === 'de' ? '[0-9]+([,][0-9]{1,2})?' : '[0-9]+([\\.][0-9]{1,2})?'} onKeypress={validateDecimal} onKeyUp={searchChangeHandler} />
                    <input type="number" min='0' step='1' id="filter-totalClicksTo" name="filter[totalClicksTo]" className="form-control d-inline-block" placeholder={t("mj_filter_to_placeholder")} pattern={i18next.language === 'de' ? '[0-9]+([,][0-9]{1,2})?' : '[0-9]+([\\.][0-9]{1,2})?'} onKeypress={validateDecimal} onKeyUp={searchChangeHandler} />
                    <span className='d-inline-block ml-2'>{t('Clicks')}</span>
                  </div>
                  <div className="form-group mt-4">
                    <label className='font-weight-bold d-block'>{t("mj_filter_max_clicks_label")}:</label>
                    <input type="number" min='0' step='1' id="filter-maxClicksFrom" name="filter[maxClicksFrom]" className="form-control d-inline-block" placeholder="0" pattern={i18next.language === 'de' ? '[0-9]+([,][0-9]{1,2})?' : '[0-9]+([\\.][0-9]{1,2})?'} onKeypress={validateDecimal} onKeyUp={searchChangeHandler} />
                    <input type="number" min='0' step='1' id="filter-maxClicksTo" name="filter[maxClicksTo]" className="form-control d-inline-block" placeholder={t("mj_filter_to_placeholder")} pattern={i18next.language === 'de' ? '[0-9]+([,][0-9]{1,2})?' : '[0-9]+([\\.][0-9]{1,2})?'} onKeypress={validateDecimal} onKeyUp={searchChangeHandler} />
                    <span className='d-inline-block ml-2'>{t('Clicks')}</span>
                  </div>
                  <div className="form-group mt-4">
                    <label className='font-weight-bold d-block'>{t("mj_filter_status_label")}:</label>

                    <div className="row">
                      <div className="col-4">
                        <div className='form-check form-check-flat m-auto d-inline-block'>
                          <label className='form-check-label checkbox'>
                            <input id='filter-online' type='checkbox' name='filter[online]' className='form-check-input' autocomplete="off" defaultValue="{t('mj_filter_status_online_label') ?>" checked onKeyUp={searchChangeHandler} /><i className="input-helper"></i>{t("mj_filter_status_online_label")}</label>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className='form-check form-check-flat m-auto d-inline-block'>
                          <label className='form-check-label checkbox'>
                            <input id='filter-paused' type='checkbox' name='filter[paused]' className='form-check-input' defaultValue={t('mj_filter_status_paused_label')} checked onKeyUp={searchChangeHandler} /><i className="input-helper"></i>{t("mj_filter_status_paused_label")}
                          </label>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className='form-check form-check-flat m-auto d-inline-block'>
                          <label className='form-check-label checkbox'>
                            <input id='filter-offline' type='checkbox' name='filter[offline]' className='form-check-input' autocomplete="off" defaultValue="{t('mj_filter_status_offline_label') ?>" checked onKeyUp={searchChangeHandler} /><i className="input-helper"></i>{t("mj_filter_status_offline_label")}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mt-4 warningBlock">
                    <label className='font-weight-bold d-block'>{t("mj_filter_other_label")}:</label>
                    <div className='form-check form-check-flat m-auto d-inline-block' style={{ width: '50%' }}>
                      <label className='form-check-label checkbox'>
                        <input id='filter-warning' type='checkbox' name='filter[warning]' className='form-check-input' autocomplete="off" defaultValue={t('mj_filter_warning_label')} onKeyUp={searchChangeHandler} /><i className="input-helper"></i>{t("mj_filter_warning_label")}</label>
                    </div>

                  </div>

                </div>
              </div>
            </form>
          </div>
        </Dropdown.Item>
        <Dropdown.Item>
          <button type="button" className="btn btn-sm btn-outline-primary btnCancelFilterTable float-right" onClick={handleAbort}>{t('job_page_click_raise_limit_cancel_text')}</button>
          <button type="button" className="btn btn-sm btn-primary btnApplyFilterTable float-right mr-2 jscdga_myjobs_action_apply_filter" onClick={onFilterChangeHandler}>{t('mj_filter_customize_tbl_save')}</button>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>

    <div className="float-right frame-13 clicks-block-date-range-block">
      <div className="date-range valign-text-middle subtitle">{t('pf_clicks_date_range')}:</div>
      <div className="button-10 border-1px-primary100">
        <div className="button-11 valign-text-middle button">
          <div id="donutchartrange" className="">
            {datePickerComponent}
          </div>
        </div>
      </div>
    </div>

  </>
}


export function setDecimalValue(number) {
  const { payload } = Session.get('login');
  const UserSession = payload;
  if (number === undefined) return '...';
  // return new Intl.NumberFormat(UserSession.intlLocale, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number);
  return new Intl.NumberFormat(i18n.language === "en" ? "en-US" : "de-DE", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number);
}
export function setStringValue(string) {
  // const { payload } = Session.get('login');
  // const UserSession = payload;
  if (string === undefined) return '...';
  if(string === '...') return <>....</>
  return string;
}
export function setIntegerValue(number) {
  const { payload } = Session.get('login');
  const UserSession = payload;
  if (number === undefined) return '...';
  if (number === 'N/A') return 0;
  if (number === '...') return 0;
  // return new Intl.NumberFormat(UserSession.intlLocale, { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(number);
  return new Intl.NumberFormat(i18n.language === "en" ? "en-US" : "de-DE", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(number);
}


export function AlertModal({ modal, setModal, modalTitle, modalMessage, backButtonText,submitButton,handleSubmitSettings ,submitButtonText,cancelBtnUrl,backButtonClassName}) {

  const handleCancel = (event) => {
    setModal(false)
    
  }
  // log.debug(cancelBtnUrl)

  return <>
    {/* <div className="modal-backdrop modal-stack extraModalBackDrop"></div> */}
    <Modal
      className={'extraModal'}
      dialogClassName={'small-dailog small modal-sm'}
      id={'extraModal'}
      onClose={() => { setModal(false);  }}
      show={modal}
      backdropClassName={'extraModalBackDrop'}
      contentClassName={'whitebg smallModal'}
      onHide={() => { setModal(false);  }}
    >
      <Modal.Header closeButton >
        <Modal.Title><span>{modalTitle}</span></Modal.Title>
      </Modal.Header>
      <ModalBodyScrollIcon className="smallModalBody"><div className="alert alert-warning ">
        <div className="row">
          <div className="col-md-1"><i style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)' }}><RedWarningSVG /></i></div>
          <div className="col-md-11 msg">{modalMessage}</div>
        </div>
      </div></ModalBodyScrollIcon>
      <Modal.Footer>
        {cancelBtnUrl !== undefined && cancelBtnUrl !== false?<Button variant="outline-primary" onClick={(e) => { window.location.href = cancelBtnUrl }}>{backButtonText}</Button>:<Button variant="outline-primary" onClick={(e) => { handleCancel(e);  }} className={backButtonClassName}>{backButtonText}</Button>}
        
        {submitButton !== undefined?<Button variant="primary" className={'finalSubmitSettings greenPrimary'} style={{ marginLeft: "0px" }} onClick={handleSubmitSettings} >{submitButtonText}</Button>:<></>}
      </Modal.Footer>
    </Modal>
  </>
}

export function PaginationDropdown({itemsPerPageChangeHandler,itemsPerPage,totalRecords}) {


  const ChangeJobStatusToggle = React.forwardRef(({ children, onClick }, ref) => (

    <Button
      className="btn btn-primary  dropdown-toggle paginationAction"
      id=""
      to="#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);

      }}
    >
      {itemsPerPage}
    </Button>
  ));
  return <>
    {<Dropdown className="d-inline">
      <Dropdown.Toggle as={ChangeJobStatusToggle}></Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-pagination">
        <Dropdown.Item className={itemsPerPage === 25?'active':''} data-value="25" onClick={itemsPerPageChangeHandler}>{setIntegerValue(25)}</Dropdown.Item>
        <Dropdown.Item className={itemsPerPage === 50?'active':''} data-value="50" onClick={itemsPerPageChangeHandler}>{setIntegerValue(50)}</Dropdown.Item>
        <Dropdown.Item className={itemsPerPage === 75?'active':''} data-value="75" onClick={itemsPerPageChangeHandler}>{setIntegerValue(75)}</Dropdown.Item>
        <Dropdown.Item className={itemsPerPage === 100?'active':''} data-value="100" onClick={itemsPerPageChangeHandler}>{setIntegerValue(100)}</Dropdown.Item>
        
      </Dropdown.Menu>
    </Dropdown>}</>
}



/**
 * This function is used to Create Job Groups Rules
 * that needs to be shown in the JobGroup Tooltip
 * @author Shubham Rastogi
 * @param {object} item 
 * @returns 
 */
export function GetJobGroupRules({arrJobGroup}){
  const { t } = useTranslation();
  const { payload } = Session.get('login');
  const UserSession = payload;
  const rulesCondition = {'equal':t('pf_jg_rel_equal'),
  'not_equal':t('pf_jg_rel_not_equal'),
  'contains':t('pf_jg_rel_contain'),
  'not_contains':t('pf_jg_rel_no_contain'),
  'starts with':t('pf_jg_rel_starts_with'),
  'does not starts with':t('pf_jg_rel_not_starts_with')}
  // log.debug('GetJobGroupRules',arrJobGroup)
  // let strRules = '';
  if(arrJobGroup.IsDefault === 'true'){
    return <div className="groupRules">
      <div className="RuleBlock">
      <div className="Ruleset"><span className="defaultRuleset" dangerouslySetInnerHTML={{__html:t('jg_default_no_rules')}}></span></div></div>
      
      </div>;
  }
  if(arrJobGroup.IsDefault === 'Yes'){
    return <div className="groupRules">
      <div className="RuleBlock">
      <div className="Ruleset"><span className="defaultRuleset" dangerouslySetInnerHTML={{__html:t('jg_pulled_by_default')}}></span></div></div>
      
      </div>;
  }else{
    var ruleIndex = 0;

    let strRuleSet = (arrJobGroup !== undefined && arrJobGroup.RuleSet !== undefined?(typeof arrJobGroup.RuleSet === 'string'?(JSON.parse(arrJobGroup.RuleSet)):arrJobGroup.RuleSet):[])
    // log.debug(strRuleSet)
    return <div className="groupRules">{strRuleSet && strRuleSet.rules !== undefined && strRuleSet.rules.map((itemRules, indexRules) => {
      if(itemRules.rules !== undefined){
        
        ruleIndex++;
        return <>{ruleIndex !== 1?<><div className="RuleOperator">{t('pf_jg_or_text')}</div></>:<></>}
        <div className="RuleBlock">
          
          <div className="RuleIndex">{t('pf_rule_text')+" "+ ruleIndex}</div>
          <div className="Ruleset">
          {itemRules.rules !== undefined && itemRules.rules.map((itemRulesN, indexRules1) => {
          
          
          return <>
          {(indexRules1 > 0 && indexRules1 <= 2)?<div className="andText">{t('and_text')}</div>:<></>}
          <div className={"rules rules_"+indexRules1} data-sl="mask">{
            (itemRulesN.id === 'title')?<>{`${t('pf_rules_title_text')} ${rulesCondition[itemRulesN.operator]}   ${itemRulesN.value}`}</>:<></>}
            {(itemRulesN.id === 'cd1')?<>{(UserSession.UIPreferences.jcd_custom_dimensions !== undefined && UserSession.UIPreferences.jcd_custom_dimensions[0][itemRulesN.id]['visible_name'][i18next.language]) + " "+rulesCondition[itemRulesN.operator]+" " + itemRulesN.value}</>:<></>}
            {(itemRulesN.id === 'cd2')?<>{(UserSession.UIPreferences.jcd_custom_dimensions !== undefined && UserSession.UIPreferences.jcd_custom_dimensions[0][itemRulesN.id]['visible_name'][i18next.language]) + " "+rulesCondition[itemRulesN.operator]+" " + itemRulesN.value}</>:<></>}
            {(itemRulesN.id === 'cd3')?<>{(UserSession.UIPreferences.jcd_custom_dimensions !== undefined && UserSession.UIPreferences.jcd_custom_dimensions[0][itemRulesN.id]['visible_name'][i18next.language]) + " "+rulesCondition[itemRulesN.operator]+" " + itemRulesN.value}</>:<></>}
            {(itemRulesN.id === 'cd4')?<>{(UserSession.UIPreferences.jcd_custom_dimensions !== undefined && UserSession.UIPreferences.jcd_custom_dimensions[0][itemRulesN.id]['visible_name'][i18next.language]) + " "+rulesCondition[itemRulesN.operator]+" " + itemRulesN.value}</>:<></>}
            {(itemRulesN.id === 'cd5')?<>{(UserSession.UIPreferences.jcd_custom_dimensions !== undefined && UserSession.UIPreferences.jcd_custom_dimensions[0][itemRulesN.id]['visible_name'][i18next.language]) + " "+rulesCondition[itemRulesN.operator]+" " + itemRulesN.value}</>:<></>}
            </div>
          </>
          
        })}</div></div></>;
       
        
      }
      return <></>
    })}</div>
  
  }
  
  
}

/**
 * This function is used to Check and Set Innovation Lab Teaser 
 * @author Shubham Rastogi
 * @param {object} item 
 * @returns 
 */
export const checkAndSetInnovationTeaser = (setShowTeaser,setTeaserData) => {
  const { payload } = Session.get('login');
  const UserSession = payload;
  const params = new URLSearchParams(window.location.search);
  const paramsUserID = params.get("theuserid");
  const paramsPreviewReleaseTileID = params.get('preview_release_tile_id');
  let theuserid = UserSession.UserID;
  log.debug('handleTeaserChange')
  if(paramsUserID != null){
      theuserid = paramsUserID;
  }

  //as innovation lab is disabled for inactive users, don't show teaser as well
  if (UserSession.no_active_campaigns_since_x_days) return;

  let postDataSettings = {
    getInnovationLab: 1,action: 'getInnovationTeaserData',preview_release_tile_id:paramsPreviewReleaseTileID, userID: theuserid, sessionData: UserSession, 
  };
  getPortfolioData(postDataSettings).then((result) => {
    if (result.Status === 'success') {
      setShowTeaser(true);
      setTeaserData(result.data)
    }else{
      setShowTeaser(false);
    }
  })
};

/**
   * Added for innovation lab tracking, click on the sidebar
   */
export const handleClickEventRecord = async (e,isModifiedGlobal,eventName) => {
  e.preventDefault();
  
  let thisHref = e.currentTarget.getAttribute('href');
  const { payload } = Session.get('login');
  const UserSession = payload;
  if(UserSession !== undefined){
    recordUserEvent({
        session_data: UserSession,
        eventName: eventName
    });
  }
  // await recordInnovationLabEvent({
  //   session_data: UserSession,
  //   event: "click",
  //   type: "sidebar",
  // });
  if(!isModifiedGlobal){
    window.location.href = thisHref;
  }
  
  
}


export const handleClickEventRecordForDropdown = async (e,eventName) => {
  e.preventDefault();
  const { payload } = Session.get('login');
  const UserSession = payload;
  if(UserSession !== undefined){
    recordUserEvent({
        session_data: UserSession,
        eventName: eventName
    });
  }  
}

export const validateEmail = (email) => {
  return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};


export function LoaderNew({}){
  return <><div className="container-fluid page-body-wrapper">
  <div className="row">
    <div className="content-wrapper full-page-wrapper d-flex align-items-center index-login auth">
      <div className="row w-100">
        <div className="col-lg-8 mx-auto">
          <div className="row">
            <h1 id="logo" className="mx-auto text-center">
              {/* <LogoIcon /> */}
              {i18n.language === 'en' ? <LogoLoaderEN  loading="lazy" className={'full_logo'} /> : <LogoLoaderDE  loading="lazy" className={'full_logo'} />}
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</div></>
}