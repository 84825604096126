import { getPortfolioData } from "../../../services";
import { arrSigninAsAdminAuthId } from "../../../config/Constants";

/**
 * Records user event.
 *
 * @param {Object} props.session_data - The session data.
 * @param {string} props.eventName - The event name.
 */
const recordUserEvent = (props) => {
  const { session_data, eventName } = props;
  const demoUser = session_data.UserName && session_data.UserName === "Demo";
  const masterPass = session_data.LoginByMasterPass === true;
  const signInAs = session_data.intAuthID && arrSigninAsAdminAuthId.includes(parseInt(session_data.intAuthID));

  return new Promise((resolve, reject) => {
    if (demoUser || masterPass || signInAs) {
      resolve({});
      return;
    }
    const data = {
      track_events: 1,
      sessionData: session_data,
      eventName: eventName,
    };

    getPortfolioData(data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export { recordUserEvent };