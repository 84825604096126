import React, { createContext, useContext, useEffect, useState } from "react";

export const WMSContext = createContext({
  is404: false,
  set404: () => { },
  navcollapse: true,
  setNavCollapse: () => { },
  navSynonymsCollapse: false,
  setNavSynonymsCollapse: () => { },
  navJQXCollapse: false,
  setNavJQXCollapse: () => { },
  navMDCollapse: false,
  setNavMDCollapse: () => { },
  isModifiedGlobal:false,
  setIsModifiedGlobal:()=>{},
  saveSettingsModifiedModal:false,
  setSaveSettingsModifiedModal:()=>{},
  failRedirectUrl:false,
  setFailRedirectUrl:()=>{},
  hasSession: false,
  setHasSession: () => { },
});

export const useWMSContext = () => useContext(WMSContext);



/**
 * Provides a context for managing the 404 error state
 * and any states to be used in the future
 *
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {ReactNode} The context provider component.
 */
export const WMSContextProvider = ({ children }) => {
  const [error404, setError404] = useState(false);

  const [errorisModifiedGlobal, setErrorIsModifiedGlobal] = useState(false);
  const [saveSettingsModifiedModalC, setSaveSettingsModifiedModalC] = useState(false);
  const [failRedirectUrlC, setFailRedirectUrlC] = useState(false);

  // Removed previous stored navigation local settings.
  if( window.location.pathname.indexOf("/login") !== -1  && localStorage.getItem('hasSessionProvider') === 'false' ){
    localStorage.removeItem('navcollapse');
    localStorage.removeItem('navSynonymsCollapse');
    localStorage.removeItem('navJQXCollapse');
    localStorage.removeItem('navMDCollapse');  
  }

  const [navcollapseC, setNavCollapseC] = useState(localStorage.getItem('navcollapse') ? (localStorage.getItem('navcollapse') === 'true' ? true : false) : false);
  const [navSynonymsCollapseC, setNavSynonymsCollapseC] = useState(localStorage.getItem('navSynonymsCollapse') ? (localStorage.getItem('navSynonymsCollapse') === 'true' ? true : false) : false);
  const [navJQXCollapseC, setNavJQXCollapseC] = useState(localStorage.getItem('navJQXCollapse') ? (localStorage.getItem('navJQXCollapse') === 'true' ? true : false) : false);
  const [navMDCollapseC, setNavMDCollapseC] = useState(localStorage.getItem('navMDCollapse') ? (localStorage.getItem('navMDCollapse') === 'true' ? true : false) : false);
  const [hasSessionProvider, setHasSessionProvider] = useState(localStorage.getItem('hasSessionProvider') ? (localStorage.getItem('hasSessionProvider') === 'true' ? true : false) : false);
  useEffect(() => {
    
    localStorage.setItem('hasSessionProvider', hasSessionProvider);  
    localStorage.setItem('navcollapse', navcollapseC);
    localStorage.setItem('navSynonymsCollapse', navSynonymsCollapseC);
    localStorage.setItem('navJQXCollapse', navJQXCollapseC);
    localStorage.setItem('navMDCollapse', navMDCollapseC);
  }, [navcollapseC,navSynonymsCollapseC,navJQXCollapseC,navMDCollapseC,hasSessionProvider])
  return <WMSContext.Provider value={{ is404: error404, set404: setError404,
    isModifiedGlobal:errorisModifiedGlobal,setIsModifiedGlobal:setErrorIsModifiedGlobal,
    failRedirectUrl:failRedirectUrlC,setFailRedirectUrl:setFailRedirectUrlC,
    saveSettingsModifiedModal:saveSettingsModifiedModalC,setSaveSettingsModifiedModal:setSaveSettingsModifiedModalC,
    navcollapse: navcollapseC, setNavCollapse: setNavCollapseC,
    navSynonymsCollapse:navSynonymsCollapseC,setNavSynonymsCollapse:setNavSynonymsCollapseC,
    navJQXCollapse:navJQXCollapseC,setNavJQXCollapse:setNavJQXCollapseC,
    navMDCollapse:navMDCollapseC,setNavMDCollapse:setNavMDCollapseC,hasSession: hasSessionProvider, setHasSession: setHasSessionProvider

   }}>{children}</WMSContext.Provider>;
};
